window.addEventListener('load', () => {
  Utils.setDatepicker();
  Utils.setSelect2();
  Utils.setMatericalContentInDropdown('#textAlign', {
    left: 'format_align_left',
    center: 'format_align_center',
    right: 'format_align_right',
    justify: 'format_align_justify',
  });
  Utils.setImageContentInDropdown('#buttonDividerShape', {
    solid: 'line.png',
    dashed: 'dashed-line.png',
    dotted: 'dotted-line.png',
  });
  Utils.shareURLScheme();
});

$(document).ready(function () {
  initAddEmailInput();
  initDirectAddInput();

  reformatPhoneNumber();
  initTabSwitching();
  initEmailTabSwitch();
  //주소록 파일 직접 입력 관련 함수 4개 - initFileSelection, initNextButton, initCheckbox, initRadioButtons

  initFileSelection();

  initCheckbox();

  initRadioButtons();
  initDirectInputRadioButtons();
  initDropdownToggle();
  initSortableColumns($('.sortable'));
  initSortableColumns($('.sortable-contents'));
  addressInitSortableColumns();
  addressDetail();
  //incoLetterMoreButtonActive();
  incoLetterShare();
  initializeDragAndDrop();

  // Sortable
  initializeSortable();
  initMoreButton('#alarmListMoreButton', '.alarm-cell');
  validateNoAlarmData();
  manyClickOpenDataCheck();
  passwordHideAndSee();
});

//비밀번호 감추기, 보이기
function passwordHideAndSee() {
  $('#togglePassword').on('click', function () {
    var passwordInput = $('#loginPassword');
    var icon = $(this);

    // 현재 비밀번호가 텍스트인지 확인
    if (passwordInput.attr('type') === 'password') {
      passwordInput.attr('type', 'text');
      icon.text('visibility'); // 아이콘을 변경
    } else {
      passwordInput.attr('type', 'password');
      icon.text('visibility_off'); // 아이콘을 변경
    }
  });
}
// 유효성 검사 함수

//많이 클릭 혹은 열어본 구독자 데이터 유무에 따른 ui 변경
function manyClickOpenDataCheck() {
  var manyClickLength = $('.many-click').find('.ng-binding').length;
  var manyOpenLength = $('.many-open').find('.ng-binding').length;
  if (manyClickLength > 0) {
    $('.many-click').show();
    $('.non-many-click').hide();
  } else {
    $('.many-click').hide();
    $('.non-many-click').show();
  }

  if (manyOpenLength > 0) {
    $('.many-open').show();
    $('.non-many-open').hide();
  } else {
    $('.many-open').hide();
    $('.non-many-open').show();
  }
}

//알림 데이터 없을 시 모두 보기 막기
function validateNoAlarmData() {
  if ($('.dropdown-body').find('.notification-card').length) {
    $('.alarm-all-page').show();
  } else {
    $('.alarm-all-page').hide();
  }
}

//이메일 에디터 콘텐츠 단계 가기 전 막기
function stepToEmailContent() {
  function isAnyRadioSelected(wrapperSelector) {
    const $radios = $(wrapperSelector).find('input[type="radio"]');
    return $radios.is(':checked');
  }

  function isValuePresent(inputName) {
    const $input = $('input[name="' + inputName + '"]');
    return $input.val().trim() !== '';
  }

  const isSelected = isAnyRadioSelected('.sender-email-address');
  const emailTitleValue = isValuePresent('emailTitle');
  const hasValue = isValuePresent('selectedAddress');
  const senderNameValue = isValuePresent('senderName');

  if (!hasValue) {
    return '주소록';
  } else if (!emailTitleValue) {
    return '이메일 제목';
  } else if (!senderNameValue) {
    return '발신자 이름';
  } else if (!isSelected) {
    return '발신자 이메일 주소';
  } else {
    return true;
  }
}

//이메일 - 탭 변경 시 예약하기 ,발송하기 show 혹은 hide
// 구독자 상세필터

// $(document).on('click', '#subscriber-detail-filter-button', function () {
//   console.log('click');
//   var id = $(this).data('id');

//   var baseUrl = "{% url 'manage_subscribers' id='999' %}".replace('999', id);
//   var targetUrl = baseUrl + '?format=html';

//   var formData = $('#filter-form').serialize();

//   $('#subscriber-filter-modal').modal('hide');
//   $('body').removeClass('modal-open');
//   $('.modal-backdrop').remove();

//   $.ajax({
//     url: targetUrl,
//     method: 'GET',
//     data: formData,
//     success: function (response) {
//       var tableBody = $(response).find('#subscriberTableBody').html();
//       var tablePagination = $(response).find('#subscriberTablePagination').html();

//       $('#subscriberTableBody').html(tableBody);
//       $('#subscriberTablePagination').html(tablePagination);

//       applyPagination();
//       currentUrl = targetUrl + '&' + formData;

//       var rowCount = $('#subscriberTableBody tr').length;
//       $('.subscriber-amount').text(rowCount);
//     },
//     error: function (xhr) {
//       // 에러 메시지 텍스트 출력
//       var errors = JSON.parse(xhr.responseText);

//       var invalidFeedback = $('#filter-form').find('.invalid-feedback');
//       invalidFeedback.text(errors.result);
//       invalidFeedback.show();
//     },
//   });
// });

//이메일 - 콘텐츠 - 데스크탑, 모바일 화면 변경 탭
function resizeEmailTemplate() {
  // 모바일 탭 클릭 시
  $(document).on('click', '.email-main-content .mobile-screen', function () {
    $('.content-list').css('width', '315px').css('min-width', '315px'); // 화면을 315px로 설정
    $('.email-main-content .content-list-empty').css('font-size', '12px');
    $('.email-main-content .mobile-screen').addClass('active'); // 모바일 탭에 active 클래스 추가
    $('.email-main-content .full-screen').removeClass('active'); // 데스크탑 탭에서 active 클래스 제거
  });

  // 데스크탑 탭 클릭 시
  $(document).on('click', '.email-main-content .full-screen', function () {
    $('.email-main-content .content-list').css('width', '630px').css('min-width', '630px'); // 화면을 630px로 설정
    $('.email-main-content .content-list-empty').css('font-size', '16px');
    $('.email-main-content .full-screen').addClass('active'); // 데스크탑 탭에 active 클래스 추가
    $('.email-main-content .mobile-screen').removeClass('active'); // 모바일 탭에 active 클래스 제거
  });
}

//이메일 - 알림 - 더보기 기능
function initMoreButton(buttonId, listClass, visibleCells = 5) {
  const $moreCells = $(listClass);
  const $moreButton = $(buttonId);

  if ($moreCells.length > visibleCells) {
    $moreButton.show().css('display', 'block'); // Show the "More" button and apply flexbox
    $moreCells.hide(); // Hide all items
    $moreCells.slice(0, visibleCells).show(); // Show the first visibleCells number of items

    let itemsShown = visibleCells;

    // Click event to show more items
    $moreButton.off('click').on('click', function () {
      itemsShown += 10; // Show 10 more items
      $moreCells.slice(0, itemsShown).show();

      // If all items are shown, hide the "More" button
      if (itemsShown >= $moreCells.length) {
        $moreButton.hide();
      }
    });
  } else {
    $moreButton.hide(); // Hide the "More" button if there are 5 or fewer items
    $moreCells.show(); // Show all items
  }
}

//이메일 - 콘텐츠 - colochip readonly 제거
function removeReadOnlyColorChip() {
  const $readOnlys = $('#contentTab').find('.color-picker-chip');
  $readOnlys.each(function () {
    $(this).removeAttr('readonly');
  });
}

//이메일 - 콘텐츠 - 상자, 스타일 화면 변경 탭
function tabEmailTemplateMakeTool() {
  const $boxTab = $('.box-tab');
  const $styleTab = $('.style-tab');

  const $boxContentBody = $('.box-style-list');
  const $styleContentBody = $('style-list');

  // 박스 탭 클릭 시
  $boxTab.on('click', function () {
    $boxTab.css('background-color', 'white');
    $boxContentBody.css('display', 'block');
    $styleTab.css('background-color', '#edeef2');
    $styleContentBody.css('display', 'none');
    $boxTab.addClass('active');
    $styleTab.removeClass('active');
  });

  // 스타일 탭 클릭 시
  $styleTab.on('click', function () {
    $styleTab.css('background-color', 'white');
    $styleContentBody.css('display', 'block');
    $boxTab.css('background-color', '#edeef2');
    $boxContentBody.css('display', 'none');
    $styleTab.addClass('active');
    $styleContentBody.removeClass('active');
  });
}

//이메일 - 콘텐츠 - 사이드바 열기, 닫기

function emailTemplateSidebarOpenClose() {
  const sidebarButton = $('.editor-sidebar-btn');
  const sidebarBody = $('.basic-template');
  const arrowIcon = $('.arrow-icon');

  const contentBody = $('.content-body'); // content-body를 선택
  const contentHeader = $('.content-head');
  const frame = $('.editing-container');
  // 사이드바 버튼 클릭 시 이벤트
  sidebarButton.on('click', function () {
    sidebarBody.toggleClass('active'); // 사이드바 열림/닫힘 토글
    arrowIcon.toggleClass('rotated'); // 아이콘 회전 토글
    closeAllSidebars();
    if (sidebarBody.hasClass('active')) {
      // 사이드바가 열릴 때 content-body 너비 줄임
      contentBody.css('width', `calc(100% - 380px)`); // 380px는 사이드바의 너비
      contentHeader.css('width', 'calc(100% - 380px)');
      sidebarButton.css('right', '380px');

      frame.css('min-width', 'calc(1024px - 380px)');
      contentHeader.css('min-width', 'calc(1024px - 380px)');
      frame.css('width', 'calc(100vw - 380px');

      contentBody.css('width', '100% - 380px');
    } else {
      // 사이드바가 닫힐 때 content-body 너비를 100%로
      frame.css('width', '100vw');
      frame.css('min-width', '1024px');
      contentBody.css('width', '100%');
      contentHeader.css('width', '100%');
      sidebarButton.css('right', '0px');
    }
  });
}

// 이메일 - 콘텐츠 - 사이드바 - 스타일 - 버튼 컴포넌트 컨트롤
function emailTemplateTextAndButtonSizeSetting() {
  // 모든 버튼 요소를 선택합니다.
  $('button').each(function () {
    var name = $(this).attr('name');
    var value = $(this).val();
    if (name) {
      Utils.templateInitJsonStructure(extractedValuesCopy, name, value);
    }
  });
}

//이메일 - 콘텐츠 - 공백 - 게이지바 값 변경 리스너 추가
function emailTemplateBlankValueListener() {
  $('#blankHeightSlider').on('input', function () {
    var sliderValue = $(this).val();
    extractedValuesCopy['blankHeightSlider'] = sliderValue;
    // 여기서 추가 UI 업데이트 로직을 구현할 수 있습니다.
  });
}
//이메일 -콘텐츠 - 사이드바 - 스타일 - 구성요소 접기, 펴기
function emailTemplateSidebarStyleComponentExpand() {
  const basicText = $('#basicTextSettingHead');
  const linkComponent = $('#linkTextSettingHead');
  const buttonComponent = $('#buttonSettingHead');
  const dividerComponent = $('#dividerLineSettingHead');

  const basicTextBody = $('#basicTextSettingBody');
  const linkComponentBody = $('#linkTextSettingBody');
  const buttonComponentBody = $('#buttonSettingBody');
  const dividerComponentBody = $('#dividerLineSettingBody');

  const basicTextToggleIcon = basicText.find('.add-icon');
  const linkToggleIcon = linkComponent.find('.add-icon');
  const buttonToggleIcon = buttonComponent.find('.add-icon');
  const dividerToggleIcon = dividerComponent.find('.add-icon');

  // 각 헤더 클릭 이벤트
  basicText.on('click', function () {
    basicText.toggleClass('active');
    if (basicText.hasClass('active')) {
      basicTextBody.css('display', 'flex');
      basicTextToggleIcon.text('remove'); // 아이콘을 '-'로 변경
    } else {
      basicTextBody.css('display', 'none');
      basicTextToggleIcon.text('add'); // 아이콘을 '+'로 변경
    }
  });

  linkComponent.on('click', function () {
    linkComponent.toggleClass('active');
    if (linkComponent.hasClass('active')) {
      linkComponentBody.css('display', 'flex');
      linkToggleIcon.text('remove'); // 아이콘을 '-'로 변경
    } else {
      linkComponentBody.css('display', 'none');
      linkToggleIcon.text('add'); // 아이콘을 '+'로 변경
    }
  });

  buttonComponent.on('click', function () {
    buttonComponent.toggleClass('active');
    if (buttonComponent.hasClass('active')) {
      buttonComponentBody.css('display', 'flex');
      buttonToggleIcon.text('remove'); // 아이콘을 '-'로 변경
    } else {
      buttonComponentBody.css('display', 'none');
      buttonToggleIcon.text('add'); // 아이콘을 '+'로 변경
    }
  });

  dividerComponent.on('click', function () {
    dividerComponent.toggleClass('active');
    if (dividerComponent.hasClass('active')) {
      dividerComponentBody.css('display', 'flex');
      dividerToggleIcon.text('remove'); // 아이콘을 '-'로 변경
    } else {
      dividerComponentBody.css('display', 'none');
      dividerToggleIcon.text('add'); // 아이콘을 '+'로 변경
    }
  });
}

// 특정 데이터 드롭박스에 대해 이벤트 핸들러 설정
function handleSelect2Values() {
  // select2 클래스를 가진 모든 select 요소를 찾음
  const selectElements = $('.select2');

  // 각 select 요소에 change 이벤트를 바인딩
  selectElements.each(function () {
    const $selectElement = $(this);
    const name = $selectElement.attr('name'); // select 요소의 name 속성을 가져옴

    // select 요소에 change 이벤트 핸들러 등록
    $selectElement.on('change', function () {
      const selectedValue = $selectElement.val(); // 선택된 value를 가져옴

      Utils.templateInitJsonStructure(extractedValuesCopy, name, selectedValue);
    });
  });
}

function initializeSortable() {
  // jQuery UI sortable 기능을 이메일 바디 tbody에 한 번만 적용
  $('.email-body tbody').sortable({
    handle: '.email-box-btn.drag', // drag_indicator 버튼을 드래그 핸들로 지정
    placeholder: 'custom-sortable-placeholder', // Use a specific class name for the placeholder
    start: function (event, ui) {
      // 드래그 시작 시 opacity 조정 및 placeholder 생성
      ui.item.css({
        opacity: 0.7,
        zIndex: 1000, // 드래그 중 최상위로 이동
        display: 'table-row',
      });
      $('.editor').css('left', -9999); // .editor 숨기기
    },
    stop: function (event, ui) {
      // 드래그 종료 시 opacity 및 위치 초기화
      ui.item.css({
        opacity: 1.0,
        zIndex: '',
      });
    },
    cancel: 'input,textarea,button,select,option,[contenteditable],.input-group.date',
  });
}

function handleDivBox($newContent) {
  // 5가지 버튼 추가
  $newContent.find('.box').each(function () {
    const $box = $(this);
    const $component = $box.parents('.component'); // .box를 포함하는 .component
    const $leftButtons = $(`
      <div class="box-left-buttons">
        <div class="email-box-btn drag"><i class="material-icons">drag_indicator</i></div>
        <div class="email-box-btn moveup"><i class="material-icons">arrow_upward</i></div>
        <div class="email-box-btn movedown"><i class="material-icons">arrow_downward</i></div>
      </div>
    `);
    // 오른쪽 버튼 그룹 생성
    const $rightButtons = $(`
      <div class="box-right-buttons">
        <div class="email-box-btn copy"><i class="material-icons">content_copy</i></div>
        <div class="email-box-btn trash"><i class="material-icons">delete</i></div>
      </div>
    `);

    // 버튼들을 박스에 추가
    $box.css('position', 'relative');
    $box.append($leftButtons).append($rightButtons);
  });
  initializeSortable();
}
// content_copy 버튼 클릭 시 .component 복사하여 아래에 추가
$(document).on('click', '.email-box-btn.copy', function () {
  let $findComponent = $(this).parents('.component');
  let $clonedComponent = $findComponent.clone(true, true);

  $clonedComponent.off('mouseenter mouseleave');
  $findComponent.after($clonedComponent);
  initializeSortable();
  $('.editing-container').trigger('editorSave');
});

// delete 버튼 클릭 시 .component 삭제
$(document).on('click', '.email-box-btn.trash', function () {
  let $findComponent = $(this).parents('.component');
  let $tableColumns = $findComponent.parents('.email-body');
  let compoeneLength = $tableColumns.find('.component').length;

  if (compoeneLength > 1) {
    $findComponent.remove();
    $('.editor').css('left', -9999).css('top', 0);
  } else {
    Utils.showToast('danger', '마지막 상자는 삭제할 수 없습니다.\n다른 상자를 추가하고 다시 삭제해주세요.');
  }
});

// arrow_upward 버튼 클릭 시 .component 위로 이동
$(document).on('click', '.email-box-btn.moveup', function () {
  let $findComponent = $(this).parents('.component');
  let $prevComponent = $findComponent.prev('.component');
  if ($prevComponent.length) {
    $prevComponent.before($findComponent);
  }
  $('.editor').css('left', -9999); // .editor 숨기기
  $(this).parents('.box').addClass('hover');
});

// arrow_downward 버튼 클릭 시 .component 아래로 이동
$(document).on('click', '.email-box-btn.movedown', function () {
  let $findComponent = $(this).parents('.component');
  let $nextComponent = $findComponent.next('.component');
  if ($nextComponent.length) {
    $nextComponent.after($findComponent);
  }
  $('.editor').css('left', -9999); // .editor 숨기기
  $(this).parents('.box').addClass('hover');
});

// .box hover 시 안에 버튼들 보이게
$(document).on('mouseenter', '.email-content .component .box', function () {
  $('.email-content .component .box').removeClass('hover');
  $(this).addClass('hover');
});
$(document).on('mouseleave', '.email-content .component .box', function () {
  $(this).removeClass('hover');
});

// 특정 데이터 버튼에 대해 이벤트 핸들러 설정
function handleButton2Values() {
  // .style-list 내부의 모든 button 요소를 찾음
  const buttonElements = $('.style-list button');

  buttonElements.each(function () {
    const $button = $(this);

    // 버튼 클릭 이벤트 핸들러 등록
    $button.on('click', function () {
      if ($button.hasClass('bold-text')) {
        // 'bold-text' 버튼의 active/inactive 기능 처리
        if ($button.hasClass('active')) {
          $button.removeClass('active').val('normal');
          extractedValuesCopy['linkTextFontWeight'] = 'normal';
          Utils.templateInitJsonStructure(extractedValuesCopy, 'linkTextFontWeight', 'normal'); // inactive 상태일 때
        } else {
          $button.addClass('active').val('bold');
          extractedValuesCopy['linkTextFontWeight'] = 'bold';
          Utils.templateInitJsonStructure(extractedValuesCopy, 'linkTextFontWeight', 'bold'); // active 상태일 때
        }
        return; // bold-text 처리 후 다른 조건문을 실행하지 않도록 종료
      }

      if ($button.hasClass('underline-text') || $button.hasClass('except-sign-text')) {
        // 'underline-text'과 'except-sign-text' 버튼의 상호 배타적 active 상태 처리
        const otherButtonClass = $button.hasClass('underline-text') ? 'except-sign-text' : 'underline-text';
        const $otherButton = $(`button.${otherButtonClass}`);

        // 현재 버튼을 active 상태로 설정하고 다른 버튼을 inactive 상태로 변경
        if ($button.hasClass('active')) {
          $button.removeClass('active');
          $otherButton.removeClass('active');
        } else {
          $button.addClass('active');
          $otherButton.removeClass('active');
        }

        // 텍스트 데코레이션을 설정
        const textDecoration = $button.hasClass('underline-text') ? 'underline' : 'line-through';
        extractedValuesCopy['linkTextTextDecoration'] = textDecoration;
        Utils.templateInitJsonStructure(extractedValuesCopy, 'linkTextTextDecoration', textDecoration);
        return; // underline-text 및 except-sign-text 처리 후 종료
      }

      if ($button.hasClass('full-width-button')) {
        // 'full-width-button'의 active/inactive 기능 처리
        if ($button.hasClass('active')) {
          $button.removeClass('active');
          extractedValuesCopy['basicButtonSize'] = 'fit-content';
          Utils.templateInitJsonStructure(extractedValuesCopy, 'basicButtonSize', 'fit-content'); // inactive 상태일 때
        } else {
          $button.addClass('active');
          extractedValuesCopy['basicButtonSize'] = '100%';
          Utils.templateInitJsonStructure(extractedValuesCopy, 'basicButtonSize', '100%'); // active 상태일 때
        }
        return; // full-width-button 처리 후 종료
      }

      if ($button.hasClass('left-alignment-button') || $button.hasClass('middle-alignment-button') || $button.hasClass('right-alignment-button')) {
        // Alignment 버튼의 상호 배타적 active 상태 처리
        const alignmentButtons = ['left-alignment-button', 'middle-alignment-button', 'right-alignment-button'];

        // 모든 alignment 버튼을 비활성화
        alignmentButtons.forEach((className) => {
          $(`.${className}`).removeClass('active');
        });

        // 현재 버튼을 active 상태로 설정
        $button.addClass('active');

        // basicButtonAlignment 값 업데이트
        const alignmentValue = $button.val(); // 현재 버튼의 value 값을 가져옴
        extractedValuesCopy['basicButtonAlignment'] = alignmentValue;
        Utils.templateInitJsonStructure(extractedValuesCopy, 'basicButtonAlignment', alignmentValue);
      }
    });
  });
}

//이메일 - 콘텐츠 - 사이드 바 - 닫기 버튼 활성화
function emailTemplateSpecificSidebarClose() {
  const sidebars = ['.divider-sidebar', '.footer-sidebar', '.blank-sidebar', '.text-sidebar', '.button-sidebar', '.layout-one-sidebar', '.web-text-sidebar'];

  sidebars.forEach((sidebar) => {
    // 각 사이드바 내부의 .btn.medium 버튼에 클릭 이벤트 바인딩
    $(`${sidebar} .btn.medium`).on('click', function () {
      // 클릭 시 사이드바 숨기기
      $(sidebar).hide();
    });
  });
}

// 먼저 모든 사이드바를 숨기고 z-index를 초기화
const sidebars = ['.divider-sidebar', '.footer-sidebar', '.blank-sidebar', '.text-sidebar', '.button-sidebar', '.layout-one-sidebar', '.web-text-sidebar'];

//이메일 - 콘텐츠 - 사이드 바 - 특정 사이드바 닫기
function closeAllSidebars() {
  sidebars.forEach((sidebar) => {
    $(sidebar).hide();
    $(sidebar).css('z-index', '1');
  });
}

//이메일 - 콘텐츠 - 사이드 바 - 특정 사이드바 열기
function emailTemplateSpecificSidebarOpenClose() {
  closeAllSidebars();
  const $contentBody = $('.content-body');

  // 각 편집 요소에 클릭 이벤트 바인딩
  $(document).on('click', '.box', function () {
    const $box = $(this);
    $('.email-body .content .box').removeClass('active');
    $box.addClass('active');

    closeAllSidebars();

    // 전체 스타일 sidebar 열기
    $('.arrow-icon').removeClass('rotated'); // 아이콘 회전 토글
    $('.content-sidebar.basic-template').addClass('active');
    $('.content-body').css('width', `calc(100% - 380px)`); // 380px는 사이드바의 너비
    $('.content-head').css('width', 'calc(100% - 380px)');
    $('.editor-sidebar-btn').css('right', '380px');
    $('.editing-container').css('min-width', 'calc(1024px - 380px)');
    $('.content-head').css('min-width', 'calc(1024px - 380px)');
    $('.editing-container').css('width', 'calc(100vw - 380px');
    $('.content-body').css('width', '100% - 380px');

    // 처음 클릭된 경우: 초기화 함수 호출
    if ($box.find('.box-row.text').length) {
      $('.text-sidebar').show().css('z-index', '10').css('background-color', 'white');
      individualComponentInitController($('.text-sidebar'), $box);
      individualComponentListenerController($('.text-sidebar'), $box); // 리스너 추가
      $contentBody.css('width', `calc(100% - 380px)`);
      buttonSidebarController($('.text-sidebar'), $box, '.editor-text');
    } else if ($box.find('.box-row.footer').length) {
      $('.footer-sidebar').show().css('z-index', '10').css('background-color', 'white');
      individualComponentInitController($('.footer-sidebar'), $box);
      individualComponentListenerController($('.footer-sidebar'), $box); // 리스너 추가
      $contentBody.css('width', `calc(100% - 380px)`);
    } else if ($box.find('.box-row.blank').length) {
      $('.blank-sidebar').show().css('z-index', '10').css('background-color', 'white');
      individualComponentInitController($('.blank-sidebar'), $box);
      individualComponentListenerController($('.blank-sidebar'), $box); // 리스너 추가
      $contentBody.css('width', `calc(100% - 380px)`);
    } else if ($box.find('.box-row.button').length) {
      $('.button-sidebar').show().css('z-index', '10').css('background-color', 'white');
      individualComponentInitController($('.button-sidebar'), $box);
      individualComponentListenerController($('.button-sidebar'), $box); // 리스너 추가
      $contentBody.css('width', `calc(100% - 380px)`);
      buttonSidebarController($('.button-sidebar'), $box, '.editor-button');
    } else if ($box.find('.box-row.divider').length) {
      $('.divider-sidebar').show().css('z-index', '10').css('background-color', 'white');
      individualComponentInitController($('.divider-sidebar'), $box);
      individualComponentListenerController($('.divider-sidebar'), $box); // 리스너 추가
      $contentBody.css('width', `calc(100% - 380px)`);
      buttonSidebarController($('.divider-sidebar'), $box, '.editor-divider');
    } else if ($box.find('.box-row.layout-one').length) {
      $('.layout-one-sidebar').show().css('z-index', '10').css('background-color', 'white');
      individualComponentInitController($('.layout-one-sidebar'), $box);
      individualComponentListenerController($('.layout-one-sidebar'), $box); // 리스너 추가
      $contentBody.css('width', `calc(100% - 380px)`);
    } else if ($box.find('.box-row.web-text').length) {
      $('.web-text-sidebar').show().css('z-index', '10').css('background-color', 'white');
      individualComponentInitController($('.web-text-sidebar'), $box);
      individualComponentListenerController($('.web-text-sidebar'), $box); // 리스너 추가
      $contentBody.css('width', `calc(100% - 380px)`);
      buttonSidebarController($('.web-text-sidebar'), $box, '.editor-web-text');
    }
  });
}

function buttonSidebarController($sideBar, $selectButton, editor) {
  // .box 아래의 .editor-text 요소의 개수를 확인합니다.
  const $box = $selectButton.closest('.box');
  const editorTextCount = $box.find(editor).length;

  // 라디오 버튼 상태를 설정합니다.
  if (editorTextCount > 1) {
    $sideBar.find('input[value="2"]').prop('checked', true);
  } else {
    $sideBar.find('input[value="1"]').prop('checked', true);
  }

  // 라디오 버튼의 변경 이벤트에 대해 buttonGridController 함수를 호출하도록 설정합니다.
  $sideBar
    .find('input')
    .off('change')
    .on('change', function () {
      buttonGridController($sideBar, $selectButton, editor);
    });
}
// 이메일 -콘텐츠 - 처음 input 속성 값 extractedValues change listener 추가
function individualComponentListenerController($sideBar, $selectButton) {
  const $box = $selectButton.closest('.box');

  // 기존에 등록된 이벤트 리스너를 제거하고 새로운 리스너를 추가합니다.
  $sideBar
    .find('input[type="color"], input[type="range"]')
    .off('input change') // 기존 리스너 제거
    .on('input change', function () {
      // 새로운 리스너 추가
      const $input = $(this);
      const key = $input.attr('data-color-picker') || $input.attr('id');

      Utils.templateUpdateValue(key, $box);
    });

  // select2를 사용하는 경우 value가 변경될 때 이벤트 처리
  $sideBar.find('select.select2').each(function () {
    const $select = $(this);

    // select2의 'change' 이벤트 리스너 추가
    $select.off('select2:select').on('select2:select', function (e) {
      const key = $select.attr('data-color-picker') || $select.attr('id');

      // 선택된 값을 반영하여 드롭박스 안의 값을 업데이트
      Utils.templateUpdateValue(key, $box);

      // select2 드롭박스 값을 트리거하여 변경
      $select.trigger('change.select2');
    });
  });
}
// 이메일 - 콘텐츠 - 전체 사이드바 컨트롤러
function basicSidebarInitController() {
  $('.style-list')
    .find('input[type="color"], select, input[type="range"]')
    .each(function () {
      const $input = $(this);
      const key = $input.attr('data-color-picker') || $input.attr('id');
      const value = extractedValuesCopy[key];
      if ($input.hasClass('select2')) {
        $input.val(value).select2();
      }
      Utils.templateInitJsonStructure(extractedValuesCopy);
    });

  // 기존에 등록된 이벤트 리스너를 제거하고 새로운 리스너를 추가합니다.
  $('.style-list')
    .find('input[type="color"], input[type="range"]')
    .off('input change') // 기존 리스너 제거
    .on('input change', function () {
      // 새로운 리스너 추가
      const $input = $(this);
      const key = $input.attr('data-color-picker') || $input.attr('id');
      extractedValuesCopy[key] = $input.val();
      Utils.templateInitJsonStructure(extractedValuesCopy);
    });

  // select2를 사용하는 경우 value가 변경될 때 이벤트 처리
  $('.style-list')
    .find('select.select2')
    .each(function () {
      const $select = $(this);

      // select2의 'change' 이벤트 리스너 추가
      $select.off('select2:select').on('select2:select', function (e) {
        const key = $select.attr('data-color-picker') || $select.attr('id');
        extractedValuesCopy[key] = $select.val();
        Utils.templateInitJsonStructure(extractedValuesCopy);
        // select2 드롭박스 값을 트리거하여 변경
        $select.trigger('change.select2');
      });
    });
}

// 이메일 -콘텐츠 - 처음 input 속성 값 extractedValues로 초기화 및 change listener 추가
function individualComponentInitController($sideBar, $box) {
  let isInitialized = $box.closest('.component').data('initialized');

  // 초기 상태에서 설정을 적용
  $sideBar.find('input[type="color"], select, input[type="range"]').each(function () {
    const $input = $(this);
    const key = $input.attr('data-color-picker') || $input.attr('id');

    if (!isInitialized) {
      const value = extractedValues[key];
      // 초기화 단계에서 select2의 값도 설정
      if ($input.hasClass('select2')) {
        $input.val(value).select2();
      }

      // Detect input type and set initial values
      const inputType = $input.attr('type');
      if (inputType === 'color' || inputType === 'range') {
        $input.val(value);
      }
      // 초기화 작업 수행

      templateInitControll(key, $box);
    } else {
      // 초기화 이후: 값이 변경되었을 때의 작업

      templateLinkData(key, $box);
    }
  });
  $box.closest('.component').data('initialized', true);
}

function rgbToHex(input, forCSS = true) {
  // 만약 이미 HEX 코드라면 바로 반환
  if (input.startsWith('#')) {
    return input;
  }

  if (forCSS) {
    if (input === 'transparent' || input === 'rgba(0, 0, 0, 0)') {
      return 'rgba(0, 0, 0, 0)';
    } else {
      // RGB 값을 HEX 코드로 변환
      const result = input.match(/\d+/g);
      return (
        '#' +
        result
          .map((x) => {
            const hex = parseInt(x).toString(16);
            return hex.length === 1 ? '0' + hex : hex;
          })
          .join('')
      );
    }
  } else {
    if (input === 'transparent' || input === 'rgba(0, 0, 0, 0)') {
      return '#ffffff';
    } else {
      // RGB 값을 HEX 코드로 변환
      const result = input.match(/\d+/g);
      return (
        '#' +
        result
          .map((x) => {
            const hex = parseInt(x).toString(16);
            return hex.length === 1 ? '0' + hex : hex;
          })
          .join('')
      );
    }
  }
}

//[컴포넌트] 지정된 값 업데이트용 함수
function templateLinkData(key, box) {
  const currentValue = extractedValuesCopy[key];
  switch (key) {
    case 'textBackground':
      var textBackgroundColor = rgbToHex(box.find('.editor-text').css('background-color'), true);
      var textColorChipColor = rgbToHex(box.find('.editor-text').css('background-color'), false);

      $('#textBackground').css('background-color', textBackgroundColor).val(textColorChipColor);
      Utils.applyColorStyle($('#textBackground'), textColorChipColor);
      break;

    case 'textBorderColor':
      var textBorderColor = rgbToHex(box.find('.editor-text').css('border-color'), true);
      var textBorderChipColor = rgbToHex(box.find('.editor-text').css('border-color'), false);

      $('#textBorderColor').css('background-color', textBorderColor).val(textBorderChipColor);
      Utils.applyColorStyle($('#textBorderColor'), textBorderChipColor);
      break;

    case 'textBorderSize':
      const textBorderSize = box.find('.editor-text').css('border-width');
      $('#textBorderSize').val(textBorderSize).trigger('change');
      break;

    case 'textInnerTopPadding':
      const textInnerTopPadding = box.find('.editor-text').css('padding-top');
      $('#textInnerTopPadding').val(textInnerTopPadding).trigger('change');
      break;

    case 'textInnerBottomPadding':
      const textBottomPadding = box.find('.editor-text').css('padding-bottom');
      $('#textInnerBottomPadding').val(textBottomPadding).trigger('change');
      break;

    case 'textInnerHorizontalPadding':
      const textHorizontalPadding = box.find('.editor-text').css('padding-left'); // Assuming left and right are the same
      $('#textInnerHorizontalPadding').val(textHorizontalPadding).trigger('change');
      break;

    case 'webTextBackground':
      var webTextBackgroundColor = rgbToHex(box.find('.editor-web-text').css('background-color'), true);
      var webTextColorChipColor = rgbToHex(box.find('.editor-web-text').css('background-color'), false);

      $('#webTextBackground').css('background-color', webTextBackgroundColor).val(webTextColorChipColor);
      Utils.applyColorStyle($('#webTextBackground'), webTextColorChipColor);
      break;

    case 'webTextBorderColor':
      var webTextBorderColor = rgbToHex(box.find('.editor-web-text').css('border-color'), true);
      var webTextBorderChipColor = rgbToHex(box.find('.editor-web-text').css('border-color'), false);

      $('#webTextBorderColor').css('background-color', webTextBorderColor).val(webTextBorderChipColor);
      Utils.applyColorStyle($('#webTextBorderColor'), webTextBorderChipColor);
      break;

    case 'webTextBorderSize':
      const webTextBorderSize = box.find('.editor-web-text').css('border-width');
      $('#webTextBorderSize').val(webTextBorderSize).trigger('change');
      break;

    case 'webTextInnerTopPadding':
      const webTextInnerTopPadding = box.find('.editor-web-text').css('padding-top');
      $('#webTextInnerTopPadding').val(webTextInnerTopPadding).trigger('change');
      break;

    case 'webTextInnerBottomPadding':
      const webTextBottomPadding = box.find('.editor-web-text').css('padding-bottom');
      $('#webTextInnerBottomPadding').val(webTextBottomPadding).trigger('change');
      break;

    case 'webTextInnerHorizontalPadding':
      const webTextHorizontalPadding = box.find('.editor-web-text').css('padding-left'); // Assuming left and right are the same
      $('#webTextInnerHorizontalPadding').val(webTextHorizontalPadding).trigger('change');
      break;

    case 'buttonBackground':
      var buttonBackgroundColor = rgbToHex(box.find('.box-row.button').css('background-color'), true);
      var buttonChipColor = rgbToHex(box.find('.box-row.button').css('background-color'), false);

      $('#buttonBackground').css('background-color', buttonBackgroundColor).val(buttonChipColor);
      Utils.applyColorStyle($('#buttonBackground'), buttonChipColor);
      break;

    case 'buttonBorderSize':
      const buttonBorderSize = box.find('.box-row.button').css('border-width');
      $('#buttonBorderSize').val(buttonBorderSize).trigger('change');
      break;

    case 'buttonInnerTopPadding':
      const buttonTopPadding = box.find('.box-row.button').css('padding-top');
      $('#buttonInnerTopPadding').val(buttonTopPadding).trigger('change');
      break;

    case 'buttonInnerBottomPadding':
      const buttonBottomPadding = box.find('.box-row.button').css('padding-bottom');
      $('#buttonInnerBottomPadding').val(buttonBottomPadding).trigger('change');
      break;

    case 'buttonHorizontalPadding':
      const buttonHorizontalPadding = box.find('.box-row.button').css('padding-left'); // Assuming left and right are the same
      $('#buttonHorizontalPadding').val(buttonHorizontalPadding).trigger('change');
      break;

    case 'buttonBorderColor':
      var buttonBorderColor = rgbToHex(box.find('.box-row.button').css('border-color'), true);
      var buttonChipBorderColor = rgbToHex(box.find('.box-row.button').css('border-color'), false);

      $('#buttonBorderColor').css('background-color', buttonBorderColor).val(buttonChipBorderColor);
      Utils.applyColorStyle($('#buttonBorderColor'), buttonChipBorderColor);
      break;

    case 'blankBorderSize':
      const blankBorderSize = box.find('.box-row.blank').css('border-width');
      $('#blankBorderSize').val(blankBorderSize).trigger('change');
      break;

    case 'blankHeightSlider':
      const blankHeight = box.find('.box-row.blank').css('height').replace('px', '');
      $('#blankHeightSlider').val(blankHeight);
      break;

    case 'blankBackground':
      var blankBackgroundColor = rgbToHex(box.find('.box-row.blank').css('background-color'), true);
      var blankChipColor = rgbToHex(box.find('.box-row.blank').css('background-color'), false);

      $('#blankBackground').css('background-color', blankBackgroundColor).val(blankChipColor);
      Utils.applyColorStyle($('#blankBackground'), blankChipColor);
      break;
    case 'blankBorderColor':
      var blankBorderColor = rgbToHex(box.find('.box-row.blank').css('border-color'), true);
      var blankChipBorderColor = rgbToHex(box.find('.box-row.blank').css('border-color'), false);

      $('#blankBorderColor').css('background-color', blankBorderColor).val(blankChipBorderColor);
      Utils.applyColorStyle($('#blankBorderColor'), blankChipBorderColor);
      break;

    case 'footerBorderSize':
      const footerBorderSize = box.find('.box-row.footer').css('border-width');
      $('#footerBorderSize').val(footerBorderSize).trigger('change');
      break;

    case 'footerInnerTopPadding':
      const footerTopPadding = box.find('.box-row.footer').css('padding-top');
      $('#footerInnerTopPadding').val(footerTopPadding).trigger('change');
      break;

    case 'footerInnerBottomPadding':
      const footerBottomPadding = box.find('.box-row.footer').css('padding-bottom');
      $('#footerInnerBottomPadding').val(footerBottomPadding).trigger('change');
      break;

    case 'footerInnerHorizontalPadding':
      const footerHorizontalPadding = box.find('.box-row.footer').css('padding-left'); // Assuming left and right are the same
      $('#footerInnerHorizontalPadding').val(footerHorizontalPadding).trigger('change');
      break;

    case 'footerBackground':
      var footerBackgroundColor = rgbToHex(box.find('.box-row.footer').css('background-color'), true);
      var footerChipColor = rgbToHex(box.find('.box-row.footer').css('background-color'), false);

      $('#footerBackground').css('background-color', footerBackgroundColor).val(footerChipColor);
      Utils.applyColorStyle($('#footerBackground'), footerChipColor);
      break;

    case 'footerBorderColor':
      var footerBorderColor = rgbToHex(box.find('.box-row.footer').css('border-color'), true);
      var footerChipBorderColor = rgbToHex(box.find('.box-row.footer').css('border-color'), false);

      $('#footerBorderColor').css('background-color', footerBorderColor).val(footerChipBorderColor);
      Utils.applyColorStyle($('#footerBorderColor'), footerChipBorderColor);
      break;

    case 'dividerBorderColor':
      var dividerBorderColor = rgbToHex(box.find('.box-row.divider').css('border-color'), true);
      var dividerChipBorderColor = rgbToHex(box.find('.box-row.divider').css('border-color'), false);

      $('#dividerBorderColor').css('background-color', dividerBorderColor).val(dividerChipBorderColor);
      Utils.applyColorStyle($('#dividerBorderColor'), dividerChipBorderColor);
      break;

    case 'dividerBorderSize':
      const dividerBorderSize = box.find('.box-row.divider').css('border-width');
      $('#dividerBorderSize').val(dividerBorderSize).trigger('change');
      break;

    case 'dividerInnerTopPadding':
      const dividerTopPadding = box.find('.box-row.divider').css('padding-top');
      $('#dividerInnerTopPadding').val(dividerTopPadding).trigger('change');
      break;

    case 'dividerInnerBottomPadding':
      const dividerBottomPadding = box.find('.box-row.divider').css('padding-bottom');
      $('#dividerInnerBottomPadding').val(dividerBottomPadding).trigger('change');
      break;

    case 'dividerHorizontalPadding':
      const dividerHorizontalPadding = box.find('.box-row.divider').css('padding-left'); // Assuming left and right are the same
      $('#dividerHorizontalPadding').val(dividerHorizontalPadding).trigger('change');
      break;

    case 'dividerBackground':
      var dividerBackgroundColor = rgbToHex(box.find('.box-row.divider').css('background-color'), true);
      var dividerChipBackgroundColor = rgbToHex(box.find('.box-row.divider').css('background-color'), false);

      $('#dividerBackground').css('background-color', dividerBackgroundColor).val(dividerChipBackgroundColor);
      Utils.applyColorStyle($('#dividerBackground'), dividerChipBackgroundColor);
      break;

    case 'layoutOneBorderSize':
      const layoutOneBorderSize = box.find('.box-row.layout-one').css('border-width');
      $('#layoutOneBorderSize').val(layoutOneBorderSize).trigger('change');
      break;

    case 'layoutOneInnerTopPadding':
      const layoutOneTopPadding = box.find('.box-row.layout-one').css('padding-top');
      $('#layoutOneInnerTopPadding').val(layoutOneTopPadding).trigger('change');
      break;

    case 'layoutOneInnerBottomPadding':
      const layoutOneBottomPadding = box.find('.box-row.layout-one').css('padding-bottom');
      $('#layoutOneInnerBottomPadding').val(layoutOneBottomPadding).trigger('change');
      break;

    case 'layoutOneHorizontalPadding':
      const layoutOneHorizontalPadding = box.find('.box-row.layout-one').css('padding-left'); // Assuming left and right are the same
      $('#layoutOneHorizontalPadding').val(layoutOneHorizontalPadding).trigger('change');
      break;

    case 'layoutOneBackground':
      var layoutOneBackgroundColor = rgbToHex(box.find('.box-row.layout-one').css('background-color'), true);
      var layoutOneChipBackgroundColor = rgbToHex(box.find('.box-row.layout-one').css('background-color'), false);

      $('#layoutOneBackground').css('background-color', layoutOneBackgroundColor).val(layoutOneChipBackgroundColor);
      Utils.applyColorStyle($('#layoutOneBackground'), layoutOneChipBackgroundColor);
      break;

    case 'layoutOneColor':
      var layoutOneBorderColor = rgbToHex(box.find('.box-row.layout-one').css('border-color'), true);
      var layoutOneChipBorderColor = rgbToHex(box.find('.box-row.layout-one').css('border-color'), false);

      $('#layoutOneColor').css('background-color', layoutOneBorderColor).val(layoutOneChipBorderColor);
      Utils.applyColorStyle($('#layoutOneColor'), layoutOneChipBorderColor);
      break;
  }
}

//[컴포넌트] 초기 저장값 초기화
function templateInitControll(key, box) {
  var selectValue = extractedValues[key];

  var colorChipColor = extractedValues[key];
  switch (key) {
    case 'textBackground':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.editor-text').css('background-color', selectValue);
      $('#textBackground').css('background-color', colorChipColor).val(selectValue);
      Utils.applyColorStyle($('#textBackground'), selectValue);
      break;
    case 'textBorderColor':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.editor-text').css('border-color', selectValue);
      $('#textBorderColor').css('background-color', colorChipColor).val(selectValue);
      Utils.applyColorStyle($('#textBorderColor'), selectValue);
      break;
    case 'textBorderSize':
      box.find('.editor-text').css('border-style', 'solid');
      box.find('.editor-text').css('border-width', selectValue);

      $('#textBorderSize').val(selectValue);
      break;
    case 'textInnerTopPadding':
      box.find('.editor-text').css('padding-top', selectValue);

      $('#textInnerTopPadding').val(selectValue);
      break;
    case 'textInnerBottomPadding':
      box.find('.editor-text').css('padding-bottom', selectValue);

      $('#textInnerBottomPadding').val(selectValue);
      break;
    case 'textInnerHorizontalPadding':
      box.find('.editor-text').css('padding-left', selectValue);
      box.find('.editor-text').css('padding-right', selectValue);
      $('#textInnerHorizontalPadding').val(selectValue);
      break;
    case 'textBackground':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.editor-text').css('background-color', selectValue);
      $('#textBackground').css('background-color', colorChipColor).val(selectValue);
      Utils.applyColorStyle($('#textBackground'), selectValue);
      break;
    case 'webTextBorderColor':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.editor-web-text').css('border-color', selectValue);
      $('#webTextBorderColor').css('background-color', colorChipColor).val(selectValue);
      Utils.applyColorStyle($('#webTextBorderColor'), selectValue);
      break;
    case 'webTextBorderSize':
      box.find('.editor-web-text').css('border-style', 'solid');
      box.find('.editor-web-text').css('border-width', selectValue);

      $('#webTextBorderSize').val(selectValue);
      break;
    case 'webTextInnerTopPadding':
      box.find('.editor-web-text').css('padding-top', selectValue);

      $('#webTextInnerTopPadding').val(selectValue);
      break;
    case 'webTextInnerBottomPadding':
      box.find('.editor-web-text').css('padding-bottom', selectValue);

      $('#webTextInnerBottomPadding').val(selectValue);
      break;
    case 'webTextInnerHorizontalPadding':
      box.find('.editor-web-text').css('padding-left', selectValue);
      box.find('.editor-web-text').css('padding-right', selectValue);
      $('#webTextInnerHorizontalPadding').val(selectValue);
      break;

    case 'buttonBackground':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.box-row.button').css('background-color', selectValue);

      $('#buttonBackground').css('background-color', colorChipColor).val(selectValue);

      Utils.applyColorStyle($('#buttonBackground'), selectValue);
      break;
    case 'buttonBorderSize':
      box.find('.box-row.button').css('border-style', 'solid'); // 여기서 항상 border-style을 설정
      box.find('.box-row.button').css('border-width', selectValue);
      $('#buttonBorderSize').val(selectValue);
      break;
    case 'buttonInnerTopPadding':
      box.find('.box-row.button').css('padding-top', selectValue);
      $('#buttonInnerTopPadding').val(selectValue);
      break;
    case 'buttonInnerBottomPadding':
      box.find('.box-row.button').css('padding-bottom', selectValue);
      $('#buttonInnerBottomPadding').val(selectValue);
      break;
    case 'buttonHorizontalPadding':
      box.find('.box-row.button').css('padding-left', selectValue);
      box.find('.box-row.button').css('padding-right', selectValue);
      $('#buttonHorizontalPadding').val(selectValue);
      break;
    case 'buttonBorderColor':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.box-row.button').css('border-color', selectValue);
      $('#buttonBorderColor').css('background-color', colorChipColor).val(selectValue);
      Utils.applyColorStyle($('#buttonBorderColor'), selectValue);
      break;

    case 'blankBorderSize':
      box.find('.box-row.blank').css('border-width', selectValue);
      $('#blankBorderSize').val(selectValue);
      break;
    case 'blankHeightSlider':
      box.find('.box-row.blank').css('height', selectValue);
      $('#blankHeightSlider').val(selectValue);
      break;
    case 'blankBackground':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.box-row.blank').css('background-color', selectValue);
      $('#blankBackground').css('background-color', colorChipColor).val(selectValue);
      Utils.applyColorStyle($('#blankBackground'), selectValue);
      break;
    case 'blankBorderColor':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.box-row.blank').css('border-color', selectValue);
      box.find('.box-row.blank').css('border-style', 'solid');
      $('#blankBorderColor').css('background-color', colorChipColor).val(selectValue);
      Utils.applyColorStyle($('#blankBorderColor'), selectValue);

      break;

    case 'footerBorderSize':
      box.find('.box-row.footer').css('border-style', 'solid');
      box.find('.box-row.footer').css('border-width', selectValue);
      $('#footerBorderSize').val(selectValue);
      break;
    case 'footerInnerTopPadding':
      box.find('.box-row.footer').css('padding-top', selectValue);
      $('#footerInnerTopPadding').val(selectValue);
      break;
    case 'footerInnerBottomPadding':
      box.find('.box-row.footer').css('padding-bottom', selectValue);
      $('#footerInnerBottomPadding').val(selectValue);
      break;
    case 'footerInnerHorizontalPadding':
      box.find('.box-row.footer').css('padding-left', selectValue);
      box.find('.box-row.footer').css('padding-right', selectValue);
      $('#footerInnerHorizontalPadding').val(selectValue);
      break;
    case 'footerBackground':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.box-row.footer').css('background-color', selectValue);
      $('#footerBackground').css('background-color', colorChipColor).val(selectValue);
      Utils.applyColorStyle($('#footerBackground'), selectValue);
      break;
    case 'footerBorderColor':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.box-row.footer').css('border-color', selectValue);
      $('#footerBorderColor').css('background-color', colorChipColor).val(selectValue);
      Utils.applyColorStyle($('#footerBorderColor'), selectValue);
      break;
    case 'dividerBorderColor':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.box-row.divider').css('border-color', selectValue);
      $('#dividerBorderColor').css('background-color', colorChipColor).val(selectValue);
      Utils.applyColorStyle($('#dividerBorderColor'), selectValue);
      break;
    case 'dividerBorderSize':
      box.find('.box-row.divider').css('border-style', 'solid');
      box.find('.box-row.divider').css('border-width', selectValue);
      $('#dividerBorderSize').val(selectValue);
      break;
    case 'dividerInnerTopPadding':
      box.find('.box-row.divider').css('padding-top', selectValue);
      $('#dividerInnerTopPadding').val(selectValue);
      break;
    case 'dividerInnerBottomPadding':
      box.find('.box-row.divider').css('padding-bottom', selectValue);
      $('#dividerInnerBottomPadding').val(selectValue);
      break;
    case 'dividerHorizontalPadding':
      box.find('.box-row.divider').css('padding-left', selectValue);
      box.find('.box-row.divider').css('padding-right', selectValue);
      $('#dividerHorizontalPadding').val(selectValue);
      break;
    case 'dividerBackground':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.box-row.divider').css('background-color', selectValue);

      $('#dividerBackground').css('background-color', colorChipColor).val(selectValue);
      Utils.applyColorStyle($('#dividerBackground'), selectValue);
      break;

    case 'layoutOneBorderSize':
      box.find('.box-row.layout-one').css('border-style', 'solid');
      box.find('.box-row.layout-one').css('border-width', selectValue);
      $('#layoutOneBorderSize').val(selectValue);
      break;
    case 'layoutOneInnerTopPadding':
      box.find('.box-row.layout-one').css('padding-top', selectValue);
      $('#layoutOneInnerTopPadding').val(selectValue);
      break;
    case 'layoutOneInnerBottomPadding':
      box.find('.box-row.layout-one').css('padding-bottom', selectValue);
      $('#layoutOneInnerBottomPadding').val(selectValue);
      break;
    case 'layoutOneHorizontalPadding':
      box.find('.box-row.layout-one').css('padding-left', selectValue);
      box.find('.box-row.layout-one').css('padding-right', selectValue);
      $('#layoutOneHorizontalPadding').val(selectValue);
      break;
    case 'layoutOneBackground':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.box-row.layout-one').css('background-color', selectValue);
      $('#layoutOneBackground').css('background-color', colorChipColor).val(selectValue);
      Utils.applyColorStyle($('#layoutOneBackground'), selectValue);
      break;
    case 'layoutOneColor':
      selectValue = rgbToHex(extractedValues[key], true);
      colorChipColor = rgbToHex(extractedValues[key], false);
      box.find('.box-row.layout-one').css('border-color', selectValue);

      $('#layoutOneColor').css('background-color', colorChipColor).val(selectValue);
      Utils.applyColorStyle($('#layoutOneColor'), selectValue);
      break;
  }
}

// 1단, 2단 그리드 컨트롤
function buttonGridController($sideBar, $selectButton, editor) {
  const $box = $selectButton.closest('.box');
  const selectedBox = $sideBar.find('input:checked');
  const selectedValue = selectedBox.val();
  var $editorText = $box.find('.box-row .editor-area');
  //$box.css('display', 'flex');

  // 1단 , 2단 관련 함수
  if (selectedValue === '2') {
    // 2단 레이아웃이 선택된 경우
    if ($editorText.length === 1) {
      const $clonedEditorText = $editorText.clone();

      $editorText.css('width', '50%');
      $clonedEditorText.css('width', '50%');
      $editorText.after($clonedEditorText);
    } else {
      $editorText.css('width', '50%');
    }
  } else if (selectedValue === '1') {
    // 1단 레이아웃이 선택된 경우
    if ($editorText.length > 1) {
      $editorText.not(':first').remove();
    }
    $editorText.css('width', '100%');
  }
  $('.editing-container').trigger('editorSave'); // 중간 저장
}

// 이메일 - 콘텐츠 - 본문 drag, drop
function initializeDragAndDrop() {
  // 드래그 중에 보여질 요소를 저장하기 위한 변수
  let draggedElement;
  const $emailBody = $('.email-body tbody');
  const $contentList = $('.content-list');
  const $contentListEmpty = $('.content-list-empty');

  const defaultBox = `
  <tr class="default-holder">
    <td>
      <div class="content">

        <div class="content-list-empty">
          <p>오른쪽 <strong>상자</strong> 메뉴에서 상자를 끌어다 놓으세요. 👉</p>
        </div>
        </div>
      </div>
    </td>
  </tr>`;

  const placeHolderBox = `<tr class="placeholder"  style="width:100%; display:inline-block; ">
  <td style="display: flex;">
    <div class="content" style="width:100%;">
      <div class="box" style="border: 1px solid #1855b9; background-color: #c4d6f4; width:100%;">
      <div class="box-row layout-one" style="display:flex; width: 100%; justify-content: center;">
          <div class="editor-area editor-text default-style" name="textArea" contenteditable="true" style="width: 100% color: #1855b9" >
            여기에 상자를 추가합니다.
          </div>
      </div>
          </div>
    </div>
  </td>
</tr>`;
  function togglePlaceholder() {
    const hasComponent = $emailBody.find('tr.component').length > 0;
    const hasPlaceholder = $emailBody.find('.placeholder').length > 0;
    const hasDefaultHolder = $emailBody.find('.default-holder').length > 0;

    if (!hasComponent && !hasPlaceholder && !hasDefaultHolder) {
      $emailBody.append(defaultBox);
    } else if (hasComponent || hasPlaceholder) {
      $emailBody.find('.default-holder').remove();
    }
  }

  // 드래그 시작 시 데이터 설정 및 복제 요소 생성
  $('.add-content-item').on('dragstart', function (e) {
    const $original = $(this);
    const clonedElement = $original.clone(); // 요소 복사

    // 마우스 위치와 요소의 경계 사이의 상대적 위치 계산
    const offsetX = e.originalEvent.clientX - $original.offset().left;
    const offsetY = e.originalEvent.clientY - $original.offset().top;

    // 복제된 요소에 원본의 스타일을 그대로 적용
    clonedElement.css({
      position: 'absolute',
      top: '-9999px',
      left: '-9999px',
      width: $original.outerWidth(),
      height: $original.outerHeight(),
      opacity: '0.7',
      border: $original.css('border'), // border 스타일 복사
      backgroundColor: $original.css('backgroundColor'), // 배경 색상 복사
      cursor: $original.css('cursor'), // 커서 스타일 복사
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: $original.find('.title').css('fontSize'), // title의 폰트 크기 복사
      color: $original.find('.title').css('color'), // title의 폰트 색상 복사
      lineHeight: $original.find('.title').css('lineHeight'), // title의 line-height 복사
      textAlign: 'center',
      userSelect: 'none',
    });

    clonedElement.find('img').css({
      width: $original.find('img').css('width'),
      height: $original.find('img').css('height'),
    });

    $('.email-body tbody').append(clonedElement); // 복제된 요소를 tbody에 추가
    // 드래그 미리보기 설정 (offsetX, offsetY를 사용하여 드래그 위치 조정)
    e.originalEvent.dataTransfer.setDragImage(clonedElement[0], offsetX, offsetY);
    e.originalEvent.dataTransfer.setData('text/plain', $original.attr('class').split(' ')[1]);

    draggedElement = clonedElement; // 전역 변수에 저장하여 나중에 제거 가능하도록 함
  });

  // 드래그가 끝났을 때 복제된 요소 제거
  $('.add-content-item').on('dragend', function () {
    if (draggedElement) {
      draggedElement.remove();
    }
  });
  var inserted = false;
  var lastMouseY = 0; // 이전 마우스 Y 위치 저장

  $emailBody.on('dragover', function (e) {
    e.preventDefault();

    const mouseY = e.originalEvent.clientY;
    if (Math.abs(mouseY - lastMouseY) < 2) {
      return;
    }

    lastMouseY = mouseY;

    let $placeholder = $emailBody.find('.placeholder');

    if ($placeholder.length === 0) {
      $emailBody.find('.default-holder').remove(); // Default holder 제거
      $placeholder = $(placeHolderBox).appendTo($emailBody);
    }

    const $rows = $emailBody.find('> tr.component');
    inserted = false;

    $rows.each(function () {
      const $row = $(this);
      const offsetTop = $row.offset().top;
      const rowHeight = $row.outerHeight();

      if (mouseY < offsetTop + rowHeight / 2) {
        if (!$row.prev().is($placeholder)) {
          $placeholder.remove();
          $row.before(placeHolderBox);
        }
        inserted = true;
        return false;
      }
    });

    if (!inserted) {
      const $lastRow = $rows.last();
      if (!$lastRow.next().is($placeholder)) {
        $placeholder.remove();
        $emailBody.append(placeHolderBox);
      }
    }

    // 드래그 중일 때 임시 콘텐츠 숨김
    $emailBody.find('.default-holder').remove();

    // 드래그 중일 때 content-list의 border 설정
    //$contentList.css('border', '1px dashed #bcbdc1');
  });

  $emailBody.on('dragleave', function (e) {
    const relatedTarget = e.originalEvent.relatedTarget;

    // relatedTarget이 없거나, emailBody 밖으로 벗어났을 때만 처리
    if (!relatedTarget || !$.contains($emailBody[0], relatedTarget)) {
      $emailBody.find('.placeholder').remove();
      $contentList.css('border', '');
      inserted = false;
      togglePlaceholder();
    }
  });

  // 추가로, mouseleave 이벤트도 활용
  $emailBody.on('mouseleave', function () {
    $emailBody.find('.placeholder').remove();
    $contentList.css('border', '');
    inserted = false;
    togglePlaceholder();
  });
  $emailBody.on('drop', function (e) {
    e.preventDefault();
    e.stopPropagation();
    // 드래그된 데이터 가져오기
    const droppedClass = e.originalEvent.dataTransfer.getData('text/plain');

    // 기존의 placeholder를 찾아서 해당 위치에 삽입할 콘텐츠 생성
    const $placeholder = $emailBody.find('.placeholder');

    let newContent = '';
    let $newContent;
    if (droppedClass === 'text-drop-button') {
      newContent = `
      <tr class="component" style="width: 100%;">
      <td>
          <div class="content" style="width: 100%;">
              <div class="box" style="width: 100%;">
                <div class="box-row text" style="display:flex; width: 100%; box-sizing: border-box;">
                    <div class="editor-area editor-text default-style" name="textArea" contenteditable="true"
                        style="width: 100%; background-color: inherit; outline: none; user-select: text; caret-color: auto;">
                        여기에 내용을 입력하세요.
                    </div>
                 </div>
              </div>
          </div>
      </td>
  </tr>`;
      $newContent = $(newContent);
      $newContent.data('initialized', false);
      individualComponentInitController($('.text-sidebar'), $newContent.find('.box'));
    }
    if (droppedClass === 'web-text-drop-button') {
      newContent = `
      <tr class="component" style="width: 100%;">
      <td>
          <div class="content" style="width: 100%;">
              <div class="box" style="width: 100%;">
                <div class="box-row web-text" style="display:flex; width: 100%; box-sizing: border-box;">
                    <div class="editor-area editor-text editor-web-text default-style web-text-no-email" name="textArea" contenteditable="true"
                        style="width: 100%; background-color: inherit; outline: none; user-select: text; caret-color: auto;">
                        <div style="text-align: right; align-items: center;">
                          <a href="" class="editor-a-button" name="letter-detail-link" style="color: #747579; font-size: 12px; text-decoration: none; "><u><font size="2">이 메일이 잘 안보이시나요?</font></u></a>
                          <span>﹒</span>
                          <a href="" class="editor-a-button" name="letter-list-link" style="color: #747579; font-size: 12px;"><u><font size="2">지난 인코레터 보기</font></u></a>
                        </div>
                    </div>
                </div>
              </div>
          </div>
      </td>
  </tr>`;
      $newContent = $(newContent);
      $newContent.data('initialized', false);
      individualComponentInitController($('.web-text-sidebar'), $newContent.find('.box'));
    } else if (droppedClass === 'image-drop-button') {
      newContent = `
      <tr class="component" style="width: 100%;">
      <td>
        <div class="content" style="width: 100%;">
          <div class="box" style="width: 100%;">
            <div class="box-row image" style="display:flex; width: 100%; box-sizing: border-box;">
              <div class="editor-area editor-image img-wrap default-style" style="width: 100%;">
                <label style="width: 100%; display: block;">
                  <input type="file" name="image" class="file-img sr-only" accept="image/*" >
                    <div class="img-preview">
                      <div class="wrap" style="background-color: #edeef2; color: #495057; font-weight: 500; text-align: center; padding: 30px 20px; border-radius: 5px; display: block; width: 100%;">
                      <i class="material-icons" style="font-size: 40px; margin-bottom: 12px;">image</i>
                      <div class="text">사진을 첨부해 주세요. [3MB 이하, 권장 사이즈 가로 630px]</div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>



            `;
      $newContent = $(newContent);
      $newContent.data('initialized', false);
    } else if (droppedClass === 'button-drop-button') {
      newContent = `
            <tr class="component" style="width: 100%;">
                <td>
                    <div class="content" style="width: 100%;">
                        <div class="box" style="width: 100%;">
                          <div class="box-row button" style="display:flex; width: 100%; box-sizing: border-box;">
                              <div class="editor-area editor-button default-style" style="width: 100%;">
                                <a class="editor-a-button" contenteditable="true" style="display: block; width: fit-content; background-color: #0076ff; color: #fff; padding: 12px; border-radius: 3px; border: 0 solid #0076ff; text-decoration: none; text-align: center;" href="javascript:void(0);" target="_blank">버튼 이름</a>
                              </div>
                          </div>

                        </div>
                    </div>
                </td>
            </tr>`;
      $newContent = $(newContent);
      $newContent.data('initialized', false);
      individualComponentInitController($('.button-sidebar'), $newContent.find('.box'));
    } else if (droppedClass === 'one-step-button') {
      newContent = `
            <tr class="component" style="width: 100%;">
                <td>
                    <div class="content" style="width: 100%;">
                        <div class="box" style="width: 100%;">
                         <div class="box-row layout-one" style="display:flex; width: 100%; box-sizing: border-box;">
                            <div class="editor-area editor-image img-wrap default-style editor-layout-one" style="width: 100%; margin-right: 8px;">
                              <label style="width 100%; display: block;">
                                <input type="file" name="image" class="file-img sr-only" accept="image/*" >
                                  <div class="img-preview">
                                    <div class="wrap" style="background-color: #edeef2; color: #495057; font-weight: 500; text-align: center; padding: 30px 20px; border-radius: 5px; display: block; width: 100%;">
                                    <i class="material-icons" style="font-size: 40px; margin-bottom: 12px;">image</i>
                                    <div class="text">사진을 첨부해 주세요. [3MB 이하, 권장 사이즈 가로 630px]</div>
                                  </div>
                                </div>
                              </label>
                            </div>
                            <div class="wrap" style="width: 100%; margin-left: 8px">
                              <div class="editor-area editor-text default-style" name="textArea" contenteditable="true"
                              style="width: 100%; background-color: inherit; outline: none; user-select: text; caret-color: auto;">
                                여기에 내용을 입력하세요.
                              </div>
                              <div class="editor-area editor-button default-style" style="width: 100%;">
                                <a class="editor-a-button" contenteditable="true" style="display: block; width: fit-content; background-color: #0076ff; color: #fff; padding: 12px; border-radius: 3px; border: 0 solid #0076ff; text-decoration: none; text-align: center;" href="javascript:void(0);" target="_blank">버튼 이름</a>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </td>
            </tr>`;
      $newContent = $(newContent);
      $newContent.data('initialized', false);
      individualComponentInitController($('.layout-one-sidebar'), $newContent.find('.box'));
    } else if (droppedClass === 'divider-button') {
      newContent = `
            <tr class="component" style="width: 100%;">
                <td>
                    <div class="content" style="width: 100%;">
                        <div class="box" style="width: 100%; min-height: 31px;">
                        <div class="box-row divider" style="display:flex; width: 100%; box-sizing: border-box;">
                              <div class="editor-divider" style="width: 100%; height:100%;">

                              </div>
                          </div>
                        </div>
                    </div>
                </td>
            </tr>`;
      $newContent = $(newContent);
      $newContent.data('initialized', false);
      individualComponentInitController($('.divider-sidebar'), $newContent.find('.box'));
    } else if (droppedClass === 'footer-button') {
      newContent = editorFooter;
      $newContent = $(newContent);
      $newContent.data('initialized', false);
      individualComponentInitController($('.footer-sidebar'), $newContent.find('.box'));
    } else if (droppedClass === 'blank-button') {
      newContent = `
            <tr class="component" style="width: 100%;">
                <td>
                    <div class="content" style="width: 100%;">
                        <div class="box" style="width: 100%;">
                        <div class="box-row blank" style="display:flex; width: 100%; box-sizing: border-box;">
                              <div class="editor-blank" style="width: 100%; height:46px;">

                              </div>
                          </div>
                        </div>
                    </div>
                </td>
            </tr>`;
      $newContent = $(newContent);
      $newContent.data('initialized', false);
      individualComponentInitController($('.blank-sidebar'), $newContent.find('.box'));
    } else if (droppedClass === 'card-button') {
      newContent = editorCard;
      $newContent = $(newContent);
    }

    $placeholder.replaceWith($newContent);

    handleDivBox($newContent);
    Utils.templateInitJsonStructure(extractedValuesCopy);

    // 드롭 후 상태 업데이트
    togglePlaceholder(); // 플래이스홀더 업데이트
    $contentListEmpty.hide(); // content-list-empty 숨김
    $contentList.css('border', ''); // 드롭 후 border 초기화
    inserted = false; // 상태 초기화
    $('.editing-container').trigger('editorSave'); // 중간 저장
  });

  // 초기 상태에서 플래이스홀더 토글
  togglePlaceholder();
}

mainStyleChangeSave();
function mainStyleChangeSave() {
  let $mainSide = $('.content-sidebar.basic-template');
  $mainSide.find('input[type=color]').each(function (index, item) {
    item.addEventListener('input', function () {
      Utils.emailColorChangeSave(); // 중간 저장 시간차
    });
  });
  $mainSide.find('input:not([type=color])').each(function (index, item) {
    $(item).on('change', function () {
      $('.editing-container').trigger('editorSave'); // 중간 저장
    });
  });
  $mainSide.find('select').each(function (index, item) {
    $(item).on('change', function () {
      $('.editing-container').trigger('editorSave'); // 중간 저장
    });
  });
  $mainSide.find('button').each(function (index, item) {
    $(item).on('click', function () {
      $('.editing-container').trigger('editorSave'); // 중간 저장
    });
  });
}

// 이메일 사진첨부
$(document).on('change', 'input[type=file].file-img', function (e) {
  let $preview = $(this).parents('.img-wrap').find('.img-preview');
  let iconImg = $(this).parents('.box-row').hasClass('cardform');
  setThumbnail($preview, e, iconImg);

  //if ($(this).val() != '' && $(this).val() != null) {
  //  setThumbnail($preview, e);
  //}
});
$(document).on('click', '.img-preview .remove-img', function (e) {
  e.preventDefault();
  e.stopPropagation();
  $(this).parents('.img-preview').find('input').val('');
  $(this).parents('.img-preview').empty();
});

// 파일 썸네일 세팅하기
function setThumbnail(preview, event, is_icon) {
  const file = event.target.files[0];

  if (file) {
    // 파일 크기 확인 (3MB = 3,145,728 바이트)
    const maxFileSize = 3 * 1024 * 1024;
    if (file.size > maxFileSize) {
      alert('파일 크기가 3MB를 초과했습니다. 3MB 이하의 파일만 첨부할 수 있습니다.');
      return;
    }

    let reader = new FileReader();
    reader.onload = function () {
      console.log(`reader: ${reader}`);
      let img = `
        <img src="${reader.result}" style="display: block; width: 100%" alt="file-img"/>
      `;
      preview.html(img);
      $('.editing-container').trigger('editorSave');
    };
    reader.readAsDataURL(file);
    // $(event.currentTarget).val(''); // 필요 시 초기화
  } else {
    // 선택 값이 비었을경우
    editorImgDelete();
    if (is_icon) {
      preview.html(`
      <div class="wrap" style="background-color: #edeef2; border-radius: 5px; display: block; width: 100%; height: 36px; line-height: 36px; text-align: center;">
        <i class="material-icons" style="font-size: 20px; vertical-align: middle">image</i>
      </div>`);
    } else {
      preview.html(`
      <div class="wrap" style="background-color: #edeef2; color: #495057; font-weight: 500; text-align: center; padding: 30px 20px; border-radius: 5px; display: block; width: 100%;">
        <i class="material-icons" style="font-size: 40px; margin-bottom: 12px;">image</i>
        <div class="text">사진을 첨부해 주세요. [3MB 이하, 권장 사이즈 가로 630px]</div>
      </div>`);
    }
    // 클릭 시 바로 변경 못하게 disabled
  }
}

// 주소록 상세 페이지 -  더보기 버튼 기능
function addressDetail() {
  // 모든 .detail-action-table을 숨김
  $('.detail-action-table').hide();

  // 첫 번째 .detail-action-table만 보이도록 설정
  $('.detail-action-table').eq(0).show();

  // 만약 .detail-action-table이 2개 이상이라면 더보기 버튼을 보이도록 설정
  if ($('.detail-action-table').length > 1) {
    $('#detailMoreButton').show();
    $('.subscriber-detail-action').css('margin-bottom', '60px');
  } else {
    $('#detailMoreButton').hide();
    $('.subscriber-detail-action').css('margin-bottom', '60px');
  }

  // 더보기 버튼 클릭 시 모든 .detail-action-table을 보이게 설정
  $('#detailMoreButton').click(function () {
    $('.detail-action-table').show();
    // 더보기 버튼을 클릭 후 숨김
    $(this).hide();
    $('.subscriber-detail-action').css('margin-bottom', '60px');
  });
}
// 주소록 만들기 - 이메일 추가하기 버튼 동작
function initAddEmailInput() {
  if ($('.address-input-main').length) {
    let inputCount = 0; // 현재 입력 필드 수를 추적

    $('.add-button').on('click', function () {
      if (inputCount < 4) {
        inputCount++;
        const newInput = `
        <div class="input-group">
          <input type="text" name="sender_email_${inputCount}" class="form-control ta-form icon-input" aria-label="이메일 입력" aria-describedby="button-addon${inputCount}">
          <div class="input-group-append">
            <button class="btn custom-btn-soft-gray delete-button" type="button" id="button-addon${inputCount}">
              <i class="material-icons delete-icon">delete</i>
            </button>
          </div>
        </div>`;
        $('#input-container').append(newInput);

        // 삭제 버튼 이벤트 리스너 추가
        $(`#button-addon${inputCount}`).on('click', function () {
          $(this).closest('.input-group').remove();
          inputCount--;
        });
      } else {
        Utils.showToast('danger', '최대 5개까지만 추가할 수 있습니다.');
      }
    });
  }
}

// 주소록 직접 추가하기 - 이메일 주소, 이름, 광고성 정보 수신 동의 버튼 동작
function initDirectAddInput() {
  if ($('.address-direct-input').length) {
    let inputCount = 0; // 현재 입력 필드 수를 추적

    const updateRemoveButtonVisibility = () => {
      if (inputCount === 0) {
        $('#infoRemoveButton').hide();
      } else {
        $('#infoRemoveButton').css('display', 'flex');
      }
    };

    updateRemoveButtonVisibility(); // 초기 상태 업데이트

    // 기존 필드 중 하나를 복사 (이 예에서는 첫 번째 필드를 복사)
    const $originalField = $('.address-direct-input-fields:first');

    const $parentField = $originalField.parent();
    $('#infoAddButton').on('click', function () {
      inputCount++;
      if (inputCount < 5) {
        // 각 필드의 ID와 name 속성 수정
        const $newField = $originalField.clone(true);
        $newField.find('input').each(function () {
          const $this = $(this);
          $this.val(``);
          // input과 같은 부모 안에 있는 invalid-feedback 요소를 찾고 display를 none으로 설정
          const $invalidFeedback = $this.closest('.address-direct-input').find('.invalid-feedback');
          $invalidFeedback.css('display', 'none');
        });
        $newField.find('.select2-container').each(function () {
          const $this = $(this);
          $this.remove();
        });
        $newField.find('.select2.dropdown').each(function () {
          const $this = $(this);

          const $inputSector = $this.parent();
          const originalId = $this.attr('id');
          const originalName = $this.attr('name');
          const $label = $this.siblings('label'); // 같은 레벨에 있는 label 요소를 선택
          const labelText = $label.text();

          // 기존 option 값들을 복사하고 data-select2-id 속성 제거
          const options = $this.children().clone(true);
          options.each(function () {
            $(this).removeAttr('data-select2-id'); // data-select2-id 속성 제거
          });

          $label.remove(); // label 요소 삭제
          $this.remove();
          // 새로운 label 요소 생성
          const $newLabel = $('<label>', {
            for: `${originalId}-${inputCount}`,
            text: labelText,
          });

          // select 뒤에 label을 추가하여 순서 보장
          $inputSector.append($newLabel);

          // 새로운 select 요소 생성 및 초기화
          const $newSelect = $('<select>', {
            class: 'select2 dropdown',
            id: `${originalId}-${inputCount}`,
            name: `${originalName}`,
            'data-placeholder': '선택',
          });

          // 기존 option 값들을 새로운 select에 붙여 넣기
          $newSelect.append(options);

          // 새로운 select 요소를 먼저 추가
          $inputSector.append($newSelect);
          // Initialize select2 on the new select element
          $newSelect.select2({
            allowClear: true,
            placeholder: '선택', // 필요에 따라 placeholder 설정
            width: 'resolve', // Optional: makes select2 auto-adjust to the container width
            minimumResultsForSearch: Infinity, // 검색 기능 비활성화
          });
        });

        $parentField.append($newField);

        updateRemoveButtonVisibility();
      } else {
        Utils.showToast('danger', '최대 5개까지만 추가할 수 있습니다.');
      }
    });

    // "삭제하기" 버튼 이벤트 리스너 추가
    $('#infoRemoveButton').on('click', function () {
      const rowLength = $('.address-direct-input-fields').length;
      var $lastInputField = $('.address-direct-input-fields:last');
      if (rowLength) {
        $lastInputField.remove();
        inputCount--;
        updateRemoveButtonVisibility();
      } else {
        Utils.showToast('danger', '더 이상 삭제할 필드가 없습니다.');
      }
    });

    // 디폴트로 모든 invalid-feedback 숨기기
    $('.invalid-feedback').hide();

    // 화면 크기 변경 이벤트 리스너 추가
    $(window).on('resize', updateRemoveButtonVisibility);
  }
}

//주소록 만들기 - 전화번호 하이픈 생성
const autoHyphen2 = (target) => {
  target.value = target.value
    .replace(/[^0-9]/g, '') // 숫자가 아닌 모든 문자 제거
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3') // 적절한 위치에 하이픈 삽입
    .replace(/(\-{1,2})$/g, ''); // 문자열 끝의 하이픈 제거
};
//주소록 새로운 주소록 - 핸드폰 번호 formatter

function reformatPhoneNumber() {
  if ($('.address-input-main').length) {
    $('#phone-number-input').on('input', function () {
      autoHyphen2(this); // 입력 필드에 입력할 때마다 autoHyphen2 함수 호출
    });
  }
}

//인코레터 페이지 - 더보기 활성화
function incoLetterMoreButtonActive() {
  // 더보기 버튼 기능
  var itemsToShow = 5; // 기본으로 보이는 셀 수
  var totalItems = $('.table-list .cell').length; // 전체 셀 수

  // 초기 셀 표시 설정
  $('.table-list .cell').hide(); // 모든 셀을 숨기고
  $('.table-list .cell').slice(0, itemsToShow).show(); // 처음 5개만 표시

  if (totalItems <= itemsToShow) {
    $('.more-button').hide(); // 셀이 5개 이하이면 더보기 버튼 숨기기
  } else {
    $('.more-button').show(); // 셀이 5개 초과이면 더보기 버튼 보이기
  }

  // 더보기 버튼 클릭 시 추가 셀 표시
  $('.more-button').click(function () {
    var visibleItems = $('.table-list .cell:visible').length;
    $('.table-list .cell')
      .slice(visibleItems, visibleItems + itemsToShow)
      .slideDown();

    if ($('.table-list .cell:visible').length >= totalItems) {
      $(this).hide(); // 모든 셀이 보이면 더보기 버튼 숨기기
    }
  });
}

//인코레터 페이지 - 공유하기 기능
function incoLetterShare() {
  // 모달 등장 위치 조절
  $('.share-button').on('click', function () {
    var buttonOffset = $(this).offset();
    var buttonHeight = $(this).outerHeight();
    var windowWidth = $(window).width();
    var modalWidth = $('#modalShare .modal-dialog').outerWidth();

    // modalShare 모달의 위치를 버튼 아래로 설정하고, 가로 중앙으로 맞춤
    $('#modalShare .modal-dialog').css({
      top: buttonOffset.top + buttonHeight + 0 + 'px', // 버튼 아래 16px
      left: (windowWidth - modalWidth - 222) / 2 + 'px', // 화면의 가로 중앙에 위치
      position: 'absolute',
    });
  });
}

// 공통 - search-button 클래스를 가진 요소를 클릭하면 형제 input 요소에 포커스
$(document).ready(function () {
  $('.search-button').on('click', function () {
    $(this).siblings('input').focus();
  });
});

// 주소록 메인 - 탭 전환용
function initTabSwitching() {
  const $tabs = $('.address-tab-button');
  const $contents = $('.address-tab-content');

  let $initialTab;

  // `isRedirected` 값에 따라 초기 탭을 설정
  var isRedirected = localStorage.getItem('isRedirected');
  if (isRedirected) {
    $initialTab = $('.address-tab-button[data-target="#subscriber-list"]');

    const dashboardTarget = $('#dashboard-button').attr('data-target');
    $(dashboardTarget).hide();

    const subscriberTarget = $('#subscriber-button').attr('data-target');
    $(subscriberTarget).show();

    localStorage.removeItem('isRedirected');
  } else {
    $initialTab = $('.address-tab-button[data-target="#dashboard"]');
  }
  $initialTab.addClass('active');

  $tabs.on('click', function () {
    localStorage.removeItem('isRedirected');

    // 모든 탭 내용과 버튼의 활성 상태를 초기화
    $tabs.removeClass('active');
    $contents.hide();

    // 현재 탭에 해당하는 내용만 보여줍니다
    $(this).addClass('active');
    const target = $(this).data('target');
    $(target).show();
  });
}

// 이메일 메인 - 탭 전환용
function initEmailTabSwitch() {
  const $tabs = $('.email-tab-button');
  const $contents = $('.email-tab-content');
  const $tabSection = $('.tab-buttons');
  const $contentSection = $('.email-main-content');

  $tabs.on('click', function () {
    // 모든 탭 내용과 버튼의 활성 상태를 초기화

    if ($(this).attr('data-target') === '#contentTab') {
      var isInputValid = stepToEmailContent();
      isInputValid = true;
      if (isInputValid === true) {
        $tabs.removeClass('active');

        const $button = $('button[role="email-main-statistic-parent"]');
        const $span = $button.find('span');

        if ($span.length) {
          $span.text('상세 통계');
          $button.css({
            color: '#dfe0e4',
            'border-bottom': '0',
          });
        }

        $contents.hide(); // 모든 탭 내용을 숨김

        // 현재 탭에 해당하는 내용만 보여줍니다
        $(this).addClass('active');
        const target = $(this).data('target');
        $(target).show();
        // contentTab 클릭 시 tabSection의 border-bottom을 화면 전체 너비로 설정
        if (target === '#contentTab') {
          $('.tab-buttons-area').addClass('active');
        } else {
          $('.tab-buttons-area').removeClass('active');
        }
      } else {
        alert(`${isInputValid}의 값을 먼저 채워주세요.`);
      }
    } else {
      $tabs.removeClass('active');

      const $button = $('button[role="email-main-statistic-parent"]');
      const $span = $button.find('span');

      if ($span.length) {
        $span.text('상세 통계');
        $button.css({
          color: '#dfe0e4',
          'border-bottom': '0',
        });
      }

      $contents.hide(); // 모든 탭 내용을 숨김

      // 현재 탭에 해당하는 내용만 보여줍니다
      $(this).addClass('active');
      const target = $(this).data('target');
      $(target).show();
      // contentTab 클릭 시 tabSection의 border-bottom을 화면 전체 너비로 설정
      if (target === '#contentTab') {
        $('.tab-buttons-area').addClass('active');
      } else {
        $('.tab-buttons-area').removeClass('active');
      }
    }
  });

  //이메일 상세 - 상세 통계 탭
  $('.email-main-statistic-dropdown-item').on('click', function (event) {
    const $button = $('button[role="email-main-statistic-parent"]');
    const $span = $button.find('span');

    if ($span.length) {
      $span.text($.trim($(event.target).text()));
      $button.css({
        'border-bottom': '2px solid #202124',
        color: '#202124',
      });
    }
  });

  //이메일 상세 - 발송정보 탭 - 코드 추가
  $('.email-main-send-info-code-dropdown-item').on('click', function () {
    const $dropdownItem = $(this);
    const value = $dropdownItem.data('value');
    const $input = $('#sendInfoCode');
    const currentValue = $input.val();

    if (!currentValue.includes(value)) {
      $input.val(currentValue ? currentValue + value : value);
    }
  });
}

function handleFiles(files) {
  // Loop through the files
  for (var i = 0; i < files.length; i++) {
    var file = files[i];

    // Check if the file is an image
    if (file.type.startsWith('image/')) {
      // Create a new image element
      var img = document.createElement('img');
      img.src = URL.createObjectURL(file);
      img.onload = function () {
        URL.revokeObjectURL(this.src);
      };

      // Append the image to the dropzone
      $dropzone.append(img);
    }
  }
}

$(function () {
  //주소록 목록 생성일 순 필터
  $('.address-list-filter-dropdown-item').each(function () {
    $(this).on('click', function (event) {
      var button = $('button[role="address-list-filter-parent"]');
      var textNode = button.contents().filter(function () {
        return this.nodeType === Node.TEXT_NODE;
      });
      if (textNode.length) {
        var span = button.find('span');
        if (span.length) {
          span.text($.trim($(event.target).text()));
        }
      }
    });
  });

  //주소록 목록 주소록 이름 검색
  $('.address-list-address-name-search-input').on('input', function () {
    var searchValue = $(this).val().toLowerCase();
    $('.dropdown-body .address-list-address-name-search-output').each(function () {
      var itemText = $(this).text().toLowerCase();
      if (itemText.includes(searchValue)) {
        $(this).parent().css('display', '');
      } else {
        $(this).parent().css('display', 'none');
      }
    });
  });

  //주소록 목록 주소록 이름 적용
  $('.dropdown-item.address-list-address-name-search-output').each(function () {
    $(this).on('click', function (event) {
      var span = $('.address-list-address-name span');
      if (span.length) {
        span.text($.trim($(event.target).text()));
      }
    });
  });

  //이메일 목록 생성일 순 필터
  $('.email-list-filter-dropdown-item').each(function () {
    $(this).on('click', function (event) {
      var button = $('button[role="email-list-filter-parent"]');
      var textNode = button.contents().filter(function () {
        return this.nodeType === Node.TEXT_NODE;
      });
      if (textNode.length) {
        var span = button.find('span');
        if (span.length) {
          span.text($.trim($(event.target).text()));
        }
      }
    });
  });

  //이메일 목록 주소록 이름 검색
  $('.address-name-search-input').on('input', function () {
    var searchValue = $(this).val().toLowerCase();
    $('.dropdown-body .address-name-search-output').each(function () {
      var itemText = $(this).text().toLowerCase();
      if (itemText.includes(searchValue)) {
        $(this).parent().css('display', '');
      } else {
        $(this).parent().css('display', 'none');
      }
    });
  });

  //이메일 목록 주소록 이름 적용
  $('.dropdown-item.address-name-search-output').each(function () {
    $(this).on('click', function (event) {
      var span = $('.email-list-address-name span');
      if (span.length) {
        span.text($.trim($(event.target).text()));
      }
    });
  });
});

// //이메일 메인 - 오픈 탭 - 마지막 오플일 순으로 정렬
// var table = $('.email-main-open-table');
// var rows = table.find('tr:gt(0)').toArray();
// var asc = true;
// $('.sorted-by-open-toggle').on('click', function () {
//   // // 정렬 로직
//   asc = !asc;

//   // i 태그의 클래스를 변경합니다.
//   var icon = $(this).find('i');
//   if (asc) {
//     icon.css('transform', 'rotate(0deg)');
//   } else {
//     icon.css('transform', 'rotate(180deg)');
//   }
// });

//이메일 메인 - 주소록 이름 검색
$('.email-main-address-search-input').on('input', function () {
  var searchValue = $(this).val().toLowerCase();
  $('.email-main-address-search-output').each(function () {
    var itemText = $(this).text().toLowerCase();
    if (itemText.includes(searchValue)) {
      $(this).parent().css('display', '');
    } else {
      $(this).parent().css('display', 'none');
    }
  });
});

//이메일 메인 - 주소록 이름 적용
$(document).on('click', '.email-main-address-search-output', function (event) {
  var span = $('.email-main-address-name span');
  var input = $('input[name="selectedAddress"]');

  // Remove 'selected' class from all items and add it to the clicked item
  $('.email-main-address-search-output').removeClass('selected');
  $(this).addClass('selected');

  if (span.length && input.length) {
    var text = $.trim(
      $(this)
        .contents()
        .filter(function () {
          return this.nodeType === 3; // Node.TEXT_NODE
        })
        .text(),
    );
    span.text(text);
    input.val(text); // Assign the text to the input's value as well
  }
});

//이메일 메인 - 세그먼트, 그룹 적용
$(document).on('click', '.email-main-address-segment-output', function (event) {
  var span = $('.email-main-segment-name');
  if (span.length) {
    var text = $.trim(
      $(this)
        .contents()
        .filter(function () {
          return this.nodeType === 3; // Node.TEXT_NODE
        })
        .text(),
    );
    span.text(text);
  }
});

//이메일 메인 - 세그먼트, 그룹 적용 내 체크박스 로직
$(document).on('click', '.dropdown-item.ta-checkbox-wrap', function (e) {
  if (e.preventDefault) {
    e.preventDefault(); // a 태그의 기본 동작을 막음
    e.stopPropagation();
  }

  var checkbox = $(this).find('input[type="checkbox"]');
  var group = checkbox.data('group');

  // 클릭한 항목의 체크 상태를 토글
  checkbox.prop('checked', !checkbox.prop('checked'));

  if (group === 0) {
    // data-group=0을 클릭한 경우, 다른 모든 그룹의 체크박스를 해제
    $('.email-main-address-segment-output[data-group!="0"]').prop('checked', false);
  } else {
    // data-group!=0을 클릭한 경우, data-group=0의 체크박스를 해제
    $('.email-main-address-segment-output[data-group="0"]').prop('checked', false);
  }

  // 선택된 체크박스의 라벨 텍스트를 span에 추가 또는 체크박스가 해제되면 해당 체크박스의 라벨 텍스트를 span에서 제거
  var span = $('.email-main-segment-name');
  if (span.length) {
    var checkedLabels = [];
    $('input[type="checkbox"]:checked').each(function () {
      var label = $('label[for="' + $(this).attr('id') + '"]').text();
      checkedLabels.push(label);
    });
    span.text(checkedLabels.length ? checkedLabels.join(', ') : '모든 구독자');
  }
});

//주소록 파일 직접 입력 관련 함수
function initFileSelection() {
  // 파일 선택 버튼 클릭 시 파일 입력 창 열기
  $('.address-file-select-button').on('click', function () {
    $('#file-input').click();
  });

  // 파일 입력 변경 시 CSV 파일 파싱 및 유효성 검사
  $('#file-input').on('change', function (event) {
    const file = event.target.files[0];
    if (file) {
      $('.input-file-name').text(file.name);
      Papa.parse(file, {
        complete: function (results) {
          if (results.data && results.data.length > 0) {
            validateCSV(results.data);
          } else {
            showError('CSV 파일이 비어 있습니다.');
          }
        },
      });
    }
  });
}

//주소록 파일 직접 입력 관련 함수
function validateCSV(data) {
  const headers = data[0].map((header) => header.trim());
  const requiredHeaders = ['이메일 주소', '이름', '광고성 정보 수신 동의', '이메일 수신거부'].map((header) => header.trim());
  const missingHeaders = requiredHeaders.filter((header) => !headers.includes(header));
  const $failIcon = $('.validate-fail-icon');
  const $successIcon = $('.validate-success-icon');
  const $errorMsg = $('.file-input-error');
  const $button = $('.address-file-select-button');

  if (missingHeaders.length > 0) {
    showError(`다음 헤더가 누락되었습니다: ${missingHeaders.join(', ')}`);
    return;
  }

  let consentIndex = headers.indexOf('광고성 정보 수신 동의');
  let optOutIndex = headers.indexOf('이메일 수신거부');
  let tbodyHtml = '';

  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    const consentValue = row[consentIndex]?.trim();
    const optOutValue = row[optOutIndex]?.trim();

    if (consentValue && consentValue !== 'Y') {
      showError(`광고성 정보 수신 동의 값이 잘못되었습니다. 행 ${i + 1}: ${consentValue}`);
      return;
    }

    if (optOutValue && optOutValue !== 'Y') {
      showError(`이메일 수신거부 값이 잘못되었습니다. 행 ${i + 1}: ${optOutValue}`);
      return;
    }

    // Generate HTML for the tbody rows
    tbodyHtml += `
      <tr>
        <td class="file-input-email" name="subscriber-email">${row[headers.indexOf('이메일 주소')]}</td>
        <td class="file-input-name" name="subscriber-name">${row[headers.indexOf('이름')]}</td>
        <td class="file-input-ads-agree" name="marketing_consent">${consentValue || ''}</td>
        <td class="file-input-email-agree" name="email_consent">${optOutValue || ''}</td>

        <input type="hidden" name="subscriber_email" value="${row[headers.indexOf('이메일 주소')]}" />
        <input type="hidden" name="subscriber_name" value="${row[headers.indexOf('이름')]}" />
        <input type="hidden" name="marketing_consent" value="${consentValue ? 'False' : 'True'}" />
        <input type="hidden" name="email_consent" value="${optOutValue ? 'False' : 'True'}" />
      </tr>`;
  }

  // Update the table tbody with parsed data
  $('.ta-table tbody').html(tbodyHtml);

  $successIcon.show();
  $failIcon.hide();
  $button.css('borderColor', '');
  $errorMsg.text('');
  initNextButton();
  checkEmails();

  Utils.showToast('danger', 'CSV 파일이 유효합니다.');

  // 다음 버튼 활성화
  $('.direct-input-button .next-button').prop('disabled', false);
}

//주소록 파일 직접 입력 관련 함수
function showError(message) {
  const $failIcon = $('.validate-fail-icon');
  const $successIcon = $('.validate-success-icon');
  const $errorMsg = $('.file-input-error');
  const $button = $('.address-file-select-button');

  $successIcon.hide();
  $failIcon.show();
  $button.css('borderColor', 'rgb(247, 53, 54)');
  $errorMsg.text(message);

  Utils.showToast('danger', message);

  // 다음 버튼 비활성화
  $('.direct-input-button .next-button').prop('disabled', true);
}
// 이메일 형식 검사 및 색상 변경 함수
function checkEmails() {
  const $tbody = $('#file-input-datas-body'); // tbody 요소 선택

  $tbody.find('tr').each(function () {
    // 각 tr 요소 반복
    const thisTr = $(this); // 현재 tr 요소

    const thisCellEmail = thisTr.find('.file-input-email'); // 클래스 이름이 file-input-email인 td 요소 찾기
    const thisCellEmailText = thisCellEmail.text().trim(); // td 요소의 텍스트 내용 가져오기

    if (!isValidEmail(thisCellEmailText)) {
      // 유효하지 않은 이메일인 경우
      thisCellEmail.css('color', 'red'); // 색상을 빨간색으로 변경
    } else {
      // 유효한 이메일인 경우
      thisCellEmail.css('color', '#596063'); // 기본 색상으로 변경
    }
  });
}

function isValidEmail(email) {
  // 이메일 형식을 검사하는 정규 표현식
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}
//주소록 파일 직접 입력 관련 함수
function initNextButton() {
  // 다음 버튼 클릭 시 페이지 전환
  $('.direct-input-button .next-button').on('click', function () {
    $('.file-input-page').hide();
    $('.file-check-page').show();
  });
}

//주소록 파일 직접 입력 관련 함수
function initCheckbox() {
  // 첫 번째 행 제외하기 체크박스 변경 시 테이블 헤더 숨기기/보이기
  $('#address_file_checkbox_1').on('change', function () {
    const $thead = $('.ta-table-wrap thead');
    if (this.checked) {
      $thead.hide();
    } else {
      $thead.show();
    }
  });
  let removedRows = [];
  $('#address_file_checkbox_2').on('change', function () {
    const $tbody = $('#file-input-datas-body');
    const isChecked = this.checked;

    if (isChecked) {
      // 체크된 경우 유효하지 않은 이메일을 가진 행을 삭제
      $tbody.find('tr').each(function () {
        const emailCell = $(this).find('.file-input-email');
        const email = emailCell.text().trim();

        if (!isValidEmail(email)) {
          removedRows.push($(this).clone()); // 행을 복원하기 위해 복사해둠

          $(this).remove(); // 행을 삭제
        }
      });
    } else {
      // 체크가 해제된 경우 삭제된 행을 복원
      removedRows.forEach(function (row) {
        $tbody.append(row); // 행을 복원
      });
      removedRows = []; // 복원 후 배열 초기화
    }
  });
}

//파일 직접 추가하기 구독자 추가 기능
function initRadioButtons() {
  // 그룹 선택 라디오 버튼 변경 시 그룹 테이블 보이기/숨기기
  const $groupSelectYes = $('#group-select-yes');
  const $groupSelectNo = $('#group-select-no');
  const $groupCellTable = $('.file-check-group-cell-tabel');
  const $groupAddButton = $('.file-check-group-add');

  $groupSelectYes.on('change', function () {
    if ($groupSelectYes.is(':checked')) {
      $groupCellTable.show();
      $groupAddButton.show();
    }
  });

  $groupSelectNo.on('change', function () {
    if ($groupSelectNo.is(':checked')) {
      $groupCellTable.hide();
      $groupAddButton.hide();
    }
  });

  // Initialize visibility based on the default selected radio button
  if ($groupSelectYes.is(':checked')) {
    $groupCellTable.show();
    $groupAddButton.show();
  } else {
    $groupCellTable.hide();
    $groupAddButton.hide();
  }
}

function initDirectInputRadioButtons() {
  // 그룹 선택 라디오 버튼 변경 시 그룹 테이블 보이기/숨기기
  const $groupSelectYes = $('#direct-input-group-radio_1');
  const $groupSelectNo = $('#direct-input-group-radio_2');
  const $groupCellTable = $('.file-check-group-cell-tabel');
  const $groupAddButton = $('.file-check-group-add');

  $groupSelectYes.on('change', function () {
    if ($groupSelectYes.is(':checked')) {
      $groupCellTable.show();
      $groupAddButton.show();
    }
  });

  $groupSelectNo.on('change', function () {
    if ($groupSelectNo.is(':checked')) {
      $groupCellTable.hide();
      $groupAddButton.hide();
    }
  });

  // Initialize visibility based on the default selected radio button
  if ($groupSelectYes.is(':checked')) {
    $groupCellTable.show();
    $groupAddButton.show();
  } else {
    $groupCellTable.hide();
    $groupAddButton.hide();
  }
}

// 주소록 드롭다운 토글 함수
function initDropdownToggle() {
  $('.address-dropdown-item').click(function (e) {
    e.stopPropagation();
    $(this).next('.dropdown-submenu').toggle();
  });
}

// 필터 오름차순, 내림차순 함수
function initSortableColumns($tableName) {
  $('.sort-icon').css('font-size', '15.5px');
  $tableName.on('click', function () {
    var $this = $(this);
    // Reset all other sortables
    $tableName.not($this).removeClass('active').find('.sort-icon').hide();

    // Toggle the current one
    $this.addClass('active');

    var $sortIcon = $this.find('.sort-icon');
    if ($sortIcon.length) {
      $sortIcon.show();

      // Get the current rotation
      var currentRotation = $sortIcon.data('rotation') || 0;
      // Increase rotation by 180 degrees
      var newRotation = currentRotation + 180;
      $sortIcon.css('transform', 'rotate(' + newRotation + 'deg)');

      // Save the new rotation value
      $sortIcon.data('rotation', newRotation);
    }
  });
}
//주소록 - 구독자 목록 - 테이블 sorting
function addressInitSortableColumns() {
  $('.sortable').on('click', function () {
    var $this = $(this);

    // Reset all other sortables
    $('.sortable').not($this).removeClass('active').find('.sort-icon').hide();

    // Toggle the current one
    $this.addClass('active');

    var $sortIcon = $this.find('.sort-icon');
    if ($sortIcon.length) {
      $sortIcon.show();

      // Check the current sort order class (asc or desc)
      if ($this.hasClass('asc')) {
        // Currently ascending, switch to descending
        $sortIcon.css('transform', 'rotate(180deg)');
        $this.removeClass('asc').addClass('desc');
      } else {
        // Currently descending, switch to ascending
        $sortIcon.css('transform', 'rotate(0deg)');
        $this.removeClass('desc').addClass('asc');
      }
    }
  });
}

//주소록 - 구독자 목록 - 필터 함수
function addressSubscriberListFilter(emailDropdownOptions) {
  const predefinedList = ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5'];

  const $dropdownMenuTemplate = $('.two-depth-dropdown-menu');
  const $dropdownButtonTemplate = $('#condition-select-dropdown');
  const $copyButtonTemplate = $('.copy-condition-inquiry');
  const $addAnotherInquiryButtonTemplate = $('.add-another-inquiry-button');
  const $addAnotherGroupInquiryButtonTemplate = $('.add-another-group-inquiry-button');
  const $inquiryDropdownTemplate = $('#subscriber-filter-condition-inquiry');
  const $betweenConditionSection = $('.subscriber-filter-between-condition-inquiry-left-section');
  const $betweenConditionInquiry = $betweenConditionSection.find('#subscriber-filter-between-condition-inquiry');

  let dropdownCounter = 1;

  const createInquirySection = (groupCount, rowCount) => {
    return `
    <div class="condition-select-section">
      <div class="dropdown-container">
        <div class="dropdown">
          <button
            class="btn btn-outline-secondary dropdown-toggle address-dropdown-toggle"
            type="button"
            id="condition-select-dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            조건을 선택하세요
            <span class="material-icons-outlined">keyboard_arrow_down</span>
          </button>
          <div class="two-depth-dropdown-menu" aria-labelledby="condition-select-dropdown">
            <div class="dropdown-menu-item">
              <input type="hidden" name="dropdown-menu-item-input-group-${groupCount}-row-${rowCount}" />
              <div class="address-dropdown-item dropdown-item active" data-target="#subscriber-info">구독자 정보</div>

              <div class="address-dropdown-item dropdown-item" data-target="#subscriber-activity">구독자 활동</div>
            </div>
            <div class="dropdown-divider"></div>
            <input type="hidden" name="dropdown-menu-sub-item-input-group-${groupCount}-row-${rowCount}" />
            <div class="submenu" id="subscriber-info">
              <a class="address-dropdown-item dropdown-item" href="#" data-type="dropdown-email">이메일 주소</a>
              <a class="address-dropdown-item dropdown-item" href="#" data-type="dropdown-name">이름</a>
              <a class="address-dropdown-item dropdown-item" href="#" data-type="dropdown-group">그룹</a>
              <a class="address-dropdown-item dropdown-item" href="#" data-type="dropdown-ads">광고성 정보 수신 동의</a>
              <a class="address-dropdown-item dropdown-item" href="#" data-type="dropdown-subscribe-date">구독일</a>
              <a class="address-dropdown-item dropdown-item" href="#" data-type="dropdown-subscribe-last-update">마지막 업데이트일</a>
            </div>
            <div class="submenu" id="subscriber-activity">
              <a class="address-dropdown-item dropdown-item" href="#" data-type="dropdown-email-success-status">발송 성공</a>
              <a class="address-dropdown-item dropdown-item" href="#" data-type="dropdown-email-fail-status">발송 실패</a>
              <a class="address-dropdown-item dropdown-item" href="#" data-type="dropdown-email-open-status">오픈</a>
              <a class="address-dropdown-item dropdown-item" href="#" data-type="dropdown-email-not-open-status">오픈 안함</a>
              <a class="address-dropdown-item dropdown-item" href="#" data-type="dropdown-email-click-status">클릭</a>
              <a class="address-dropdown-item dropdown-item" href="#" data-type="dropdown-email-not-click-status">클릭 안함</a>
            </div>
          </div>

          <!-- 이름, 이메일 클릭 시 보이는 부분 -->
          <div class="dropdown-kind-name-email" style="display: none">
            <p class="two-depth-dropdown-preposition">에</p>
            <input
              type="text"
              name="email-field-group-${groupCount}-row-${rowCount}"
              id="two-depth-dropdown-input-field"
              class="form-control ta-form two-depth-dropdown-input"
              placeholder="값을 입력해주세요."
            />
            <p class="two-depth-dropdown-preposition">이</p>
            <select class="select2 dropdown two-depth-dropdown-include" data-placeholder="선택" name="email-field-include-group-${groupCount}-row-${rowCount}">
              <option value="email-field-include-contain">포함됨</option>
              <option value="email-field-include-not-contain">포함되지 않음</option>
              <option value="email-field-include-equal">같음</option>
              <option value="email-field-include-not-equal">같지 않음</option>
              <option value="email-field-include-not-start">시작함</option>
              <option value="email-field-include-not-end">끝남</option>
            </select>
          </div>

          <!-- 그룹 클릭 시 보이는 부분 -->
          <div class="dropdown-kind-group" style="display: none">
            <p class="two-depth-dropdown-preposition">에</p>
            <select class="select2 dropdown two-depth-dropdown-include" data-placeholder="선택" name="group-field-select-group-${groupCount}-row-${rowCount}">
            ${emailDropdownOptions.map((option) => `<option value="${option.id}">${option.text}</option>`).join('')}</select>
            <p class="two-depth-dropdown-preposition">이</p>
            <select class="select2 dropdown two-depth-dropdown-include" data-placeholder="선택" name="group-field-agree-group-${groupCount}-row-${rowCount}">
              <option value="group-field-agree-contain">동의</option>
              <option value="group-field-agree-not-contain">동의하지 않음</option>
            </select>
          </div>

          <!-- 광고성 정보 수신 동의 클릭 시 보이는 부분 -->
          <div class="dropdown-kind-add-agree" style="display: none">
            <p class="two-depth-dropdown-preposition">이</p>
            <select class="select2 dropdown two-depth-dropdown-include" data-placeholder="선택" name="ads-field-agree-group-${groupCount}-row-${rowCount}">
              <option value="ads-field-agree-contain">동의</option>
              <option value="ads-field-agree-not-contain">동의하지 않음</option>
            </select>
          </div>

          <!-- 구독일, 마지막 업데이트 일 클릭 시 보이는 부분 -->
          <div class="dropdown-kind-add-date" style="display: none">
            <p class="two-depth-dropdown-preposition">이</p>
            <select class="select2 dropdown two-depth-dropdown-include-wide" data-placeholder="선택" name="date-choice-section-group-${groupCount}-row-${rowCount}">
              <option value="date-choice-section-recent-week">최근 7일</option>
              <option value="date-choice-section-recent-month">최근 30일</option>
              <option value="date-choice-section-recent-three-month">최근 90일</option>
              <option value="date-choice-section-today">오늘</option>
              <option value="date-choice-section-this-month">이번 달</option>
              <option value="date-choice-section-last-month">저번 달</option>
              <option value="date-choice-section-this-month-without-year">(연도 제외)이번 달</option>
              <option value="date-choice-section-month-without-month">(연도 제외)월.</option>
              <option value="date-choice-section-month-day-without-month">(연도 제외)월. 일.</option>
              <option value="date-choice-section-vacant">비어있음</option>
              <option value="date-choice-section-occupied">비어있지 않음</option>
              <option value="date-choice-section-direct" data-icon="chevron_right">날짜 직접 선택</option>
            </select>
            <!-- NOTE: 미래 날짜 validation 해야함. -->
            <p class="two-depth-dropdown-preposition-same" style="display: none">과 같음</p>
            <p class="two-depth-dropdown-preposition-long" style="display: none">이내 (당일포함)</p>
            <div class="two-depth-dropdown-preposition-specific-month" style="display: none">
              <p class="two-depth-dropdown-preposition">이</p>
              <select class="select2 dropdown two-depth-dropdown-include" data-placeholder="선택" name="date-month-same-section-group-${groupCount}-row-${rowCount}">
                <option value="date-month-same-section-january" selected>1월</option>
                <option value="date-month-same-section-february">2월</option>
                <option value="date-choice-section-april">4월</option>
                <option value="date-choice-section-march">3월</option>
                <option value="date-choice-section-may">5월</option>
                <option value="date-choice-section-june">6월</option>
                <option value="date-choice-section-july">7월</option>
                <option value="date-choice-section-august">8월</option>
                <option value="date-choice-section-september">9월</option>
                <option value="date-choice-section-october">10월</option>
                <option value="date-choice-section-november">11월</option>
                <option value="date-choice-section-december">12월</option>
              </select>
              <p class="two-depth-dropdown-preposition-long">과 같음</p>
            </div>

            <div class="two-depth-dropdown-preposition-specific-month-day" style="display: none">
              <p class="two-depth-dropdown-preposition">이</p>
              <select class="select2 dropdown two-depth-dropdown-include" data-placeholder="선택" name="date-except-section-month-group-${groupCount}-row-${rowCount}">
                <option value="date-except-section-month-january" selected>1월</option>
                <option value="date-except-section-month-february">2월</option>
                <option value="date-except-section-month-march">3월</option>
                <option value="date-except-section-month-april">4월</option>
                <option value="date-except-section-month-may">5월</option>
                <option value="date-except-section-month-june">6월</option>
                <option value="date-except-section-month-july">7월</option>
                <option value="date-except-section-month-august">8월</option>
                <option value="date-except-section-month-september">9월</option>
                <option value="date-except-section-month-october">10월</option>
                <option value="date-except-section-month-november">11월</option>
                <option value="date-except-section-month-december">12월</option>
              </select>
              <input
                type="text"
                name="date-except-section-date-group-${groupCount}-row-${rowCount}"
                id="two-depth-dropdown-input-field"
                class="form-control ta-form two-depth-dropdown-input"
                placeholder="값을 입력해주세요."
              />
              <p class="two-depth-dropdown-preposition-long">일과 같음</p>
            </div>

            <div class="two-depth-dropdown-preposition-direct-input" style="display: none">
              <div class="ta-date-wrap">
                <input type="text" name="calendar-section-group-${groupCount}-row-${rowCount}" id="two-depth-dropdown-preposition-direct-input-calendar" class="form-control ta-form datepicker" autocomplete="off" />
                <i class="material-icons">calendar_month</i>
              </div>
              <p class="two-depth-dropdown-preposition-first-mention" style="display: none">과</p>
              <div class="two-depth-dropdown-preposition-direct-input-before-after-time" style="display: none">
                <select class="select2 dropdown two-depth-dropdown-include" data-placeholder="선택" name="calendar-section-condition-group-${groupCount}-row-${rowCount}">
                  <option value="calendar-section-condition-same">같음</option>
                  <option value="calendar-section-condition-before">이전 (당일 포함)</option>
                  <option value="calendar-section-condition-after">이후 (당일 포함)</option>
                </select>
              </div>
            </div>
          </div>

          <!-- 구독자 활동을 클릭 시 보이는 부분 -->
          <div class="dropdown-activity" style="display: none">
            <select class="select2 dropdown dropdown-activity-options" data-placeholder="선택" name="dropdown-activity-condition-group-${groupCount}-row-${rowCount}">
              <option value="dropdown-activity-recent-one">최근 1개</option>
              <option value="dropdown-activity-recent-three">최근 3개</option>
              <option value="dropdown-activity-recent-five">최근 5개</option>
              <option value="dropdown-activity-recent-seven-days">최근 7일 동안 발송한 이메일</option>
              <option value="dropdown-activity-recent-one-month">최근 30일 동안 발송한 이메일</option>
              <option value="dropdown-activity-recent-three-months">최근 90일 동안 발송한 이메일</option>
            </select>
          </div>
        </div>

        <div class="new-drop-down-function">
          <button class="delete-condition-inquiry" style="display: none" type="button">
            <i class="material-icons-outlined delete-icon">delete</i>
          </button>
          <button class="copy-condition-inquiry" type="button">
            <i class="material-icons copy-icon">content_copy</i>
          </button>
        </div>
      </div>
    </div>`;
  };

  const generateUniqueId = (baseId) => {
    return `${baseId}-${dropdownCounter++}`;
  };

  const selectInitialItem = ($menu) => {
    const $items = $menu.find('.dropdown-item[data-target]');
    $items.removeClass('active');
    const $initialItem = $menu.find('.dropdown-item[data-target="#subscriber-info"]');
    $initialItem.addClass('active');
    $menu.find('.submenu').hide();
    const $targetMenu = $menu.find($initialItem.data('target'));
    if ($targetMenu.length) {
      $targetMenu.show();
    }
    updateConditionGroupNumbers();
  };

  const addDropdownListeners = ($menu, $button) => {
    const $items = $menu.find('.dropdown-item[data-target]');

    $items.on('click', function () {
      $items.removeClass('active');
      $(this).addClass('active');

      const targetId = $(this).data('target');
      $menu.find('.submenu').hide();
      const $targetMenu = $menu.find(targetId);

      if ($targetMenu.length) {
        $targetMenu.show();
      }

      const $hiddenInput = $(this).closest('.dropdown-container').find('input[name*="dropdown-menu-item-input"]');
      $hiddenInput.val(targetId.replace('#', '')); // Remove the '#' from targetId// Remove the '#' from targetId
    });

    const $subItems = $menu.find('.submenu .dropdown-item');

    $subItems.on('click', function (e) {
      e.preventDefault(); // Prevent default link behavior
      $button.html(`${$(this).text()} <span class="material-icons-outlined">keyboard_arrow_down</span>`);
      $menu.hide();
      $button.attr('aria-expanded', 'false');

      const $parentContainer = $(this).closest('.condition-select-section');
      $parentContainer.find('.dropdown-kind-name-email, .dropdown-kind-group, .dropdown-kind-add-agree, .dropdown-kind-add-date, .dropdown-activity').hide();

      var dataType = $(this).data('type');
      var $targetContainer;

      if (dataType === 'dropdown-email' || dataType === 'dropdown-name') {
        $targetContainer = $parentContainer.find('.dropdown-kind-name-email');
      } else if (dataType === 'dropdown-group') {
        $targetContainer = $parentContainer.find('.dropdown-kind-group');
        populateSelect2($targetContainer.find('.two-depth-dropdown-include.group-element'), emailDropdownOptions);
      } else if (dataType === 'dropdown-ads') {
        $targetContainer = $parentContainer.find('.dropdown-kind-add-agree');
      } else if (dataType === 'dropdown-subscribe-date' || dataType === 'dropdown-subscribe-last-update') {
        $targetContainer = $parentContainer.find('.dropdown-kind-add-date');

        // Using event delegation to handle dynamic elements
        $targetContainer.on('change', '.two-depth-dropdown-include-wide', function () {
          const value = $(this).val();
          const $parentContainer = $(this).closest('.dropdown-kind-add-date');
          $parentContainer
            .find(
              '.two-depth-dropdown-preposition-long, .two-depth-dropdown-preposition-same, .two-depth-dropdown-preposition-specific-month, .two-depth-dropdown-preposition-specific-month-day, .two-depth-dropdown-preposition-before-after-time, .two-depth-dropdown-preposition-direct-input',
            )
            .hide();

          if (
            value === 'date-choice-section-recent-week' ||
            value === 'date-choice-section-recent-month' ||
            value === 'date-choice-section-recent-three-month' ||
            value === 'date-choice-section-this-month' ||
            value === 'date-choice-section-this-month-without-year'
          ) {
            $parentContainer.find('.two-depth-dropdown-preposition-long').show();
          } else if (value === 'date-choice-section-today' || value === 'date-choice-section-last-month') {
            $parentContainer.find('.two-depth-dropdown-preposition-same').show();
          } else if (value === 'date-choice-section-month-without-month') {
            $parentContainer.find('.two-depth-dropdown-preposition-specific-month').show();
          } else if (value === 'date-choice-section-month-day-without-month') {
            $parentContainer.find('.two-depth-dropdown-preposition-specific-month-day').show();
          } else if (value === 'date-choice-section-direct') {
            const directDateInputCalendar = $parentContainer.find('.two-depth-dropdown-preposition-direct-input');
            directDateInputCalendar.show();
            const inputCalenar = directDateInputCalendar.find('.datepicker');
            Utils.setDatepicker();
            directDateInputCalendar.on('change', inputCalenar, function () {
              directDateInputCalendar.find('.two-depth-dropdown-preposition-first-mention, .two-depth-dropdown-preposition-direct-input-before-after-time').show();
            });
          }
        });
      } else {
        $targetContainer = $parentContainer.find('.dropdown-activity');
      }
      if ($targetContainer && $targetContainer.length) {
        $targetContainer.css({ display: 'flex', alignItems: 'center' });
        Utils.setSelect2();
      }
      var targetContainerId = $(this).data('type');
      const $hiddenInput = $parentContainer.find('input[name*="dropdown-menu-sub-item-input"]');
      $hiddenInput.val(targetContainerId);
    });

    $button.on('click', function (event) {
      event.stopPropagation();
      const expanded = $(this).attr('aria-expanded') === 'true';
      if (expanded) {
        $menu.hide();
        $(this).attr('aria-expanded', 'false');
      } else {
        closeAllDropdowns();
        $menu.css('display', 'flex');
        $(this).attr('aria-expanded', 'true');
        selectInitialItem($menu);
      }
    });
  };

  const populateSelect2 = ($select, data) => {
    $select.empty(); // Clear any existing options

    data.forEach((item) => {
      const newOption = new Option(item.text, item.id, false, false);
      $select.append(newOption);
    });

    $select.trigger('change'); // Refresh the Select2 UI
  };
  const closeAllDropdowns = () => {
    $('.two-depth-dropdown-menu').hide();
    $('.dropdown-toggle').attr('aria-expanded', 'false');
  };

  const addCopyButtonListeners = ($button) => {
    $button.on('click', function () {
      closeAllDropdowns();
      const $parentSection = $button.closest('.condition-select-section');
      const $parentContainer = $button.closest('.subscriber-filter-modal-index');

      // 기존 select2에서 선택된 값을 추출
      var selectedValues = [];
      $parentSection.find('.select2').each(function () {
        const $select = $(this);
        selectedValues.push($select.val());
      });

      // 기존 datepicker의 값을 추출
      var selectedDatepickerValues = [];
      $parentSection.find('.datepicker').each(function () {
        const $input = $(this);
        selectedDatepickerValues.push($input.val());
      });

      // 기존 hidden input 값 추출

      const menuItemInputValue = $parentSection.find('.dropdown-menu-item-input').val();
      const subMenuItemInputValue = $parentSection.find('.dropdown-menu-sub-item-input').val();

      // 섹션 복제
      const $clonedSection = $parentSection.clone();

      $clonedSection.find('[id]').each(function () {
        const originalId = $(this).attr('id');
        if (originalId !== 'subscriber-info' && originalId !== 'subscriber-activity') {
          $(this).attr('id', generateUniqueId(originalId));
        }
      });

      // Add new unique data-select2-id to cloned elements if necessary
      $clonedSection.find('[data-select2-id]').each(function () {
        $(this).attr('data-select2-id', generateUniqueId('select2-id'));
      });

      // Add new unique id to datepicker elements if necessary
      $clonedSection.find('.datepicker').each(function () {
        const originalId = $(this).attr('id');
        $(this).removeClass('hasDatepicker'); // Remove hasDatepicker class
        $(this).attr('id', generateUniqueId(originalId));
      });
      // 클론된 섹션의 select2 컨테이너 제거
      $clonedSection.find('.select2-container').remove();

      // 이벤트 리스너를 클론된 섹션에 추가
      addDropdownListeners($clonedSection.find('.two-depth-dropdown-menu'), $clonedSection.find('.dropdown-toggle'));
      addCopyButtonListeners($clonedSection.find('.copy-condition-inquiry'));
      addDeleteButtonListeners($clonedSection.find('.delete-condition-inquiry'));

      // 클론된 섹션 삽입
      $parentSection.after($clonedSection);

      // Select2 초기화
      Utils.setSelect2();
      // Initialize select2 on cloned section and restore selected values
      $clonedSection.find('.select2').each(function (index) {
        const $select = $(this);
        const originalValue = selectedValues[index];

        if (originalValue) {
          // 각 option 요소에 대해 값을 설정
          $select.find('option').each(function () {
            if (originalValue.includes($(this).val())) {
              // 선택된 값을 select2에 적용하고 change 이벤트 트리거
              $select.val(originalValue).trigger('change');
            }
          });
        }
      });

      // Datepicker 초기화 및 선택된 값 복원
      Utils.setDatepicker(); // Datepicker 초기화

      $clonedSection.find('.datepicker').each(function (index) {
        const $input = $(this);
        const originalValue = selectedDatepickerValues[index];

        if (originalValue) {
          // 선택된 값을 datepicker에 적용
          $input.val(originalValue);
        }
      });

      $clonedSection.find('.dropdown-menu-item-input').val(menuItemInputValue);
      $clonedSection.find('.dropdown-menu-sub-item-input').val(subMenuItemInputValue);

      // Update section names after cloning
      updateGroupAndRowCounts();

      updateInquirySelectState(); // 새로운 상태 업데이트
      updateDeleteButtons(); // 새로운 상태 업데이트
    });
  };

  const updateGroupAndRowCounts = () => {
    $('.subscriber-filter-modal-index').each(function (groupIndex) {
      const $group = $(this);
      $group.find('.condition-group-inquiry').text(`조건 그룹 ${groupIndex + 1}`);
      $group.find('select.select2.custom-select2.dropdown').each(function (rowIndex) {
        const newGroupCount = groupIndex + 1;
        const newName = `subscriber-filter-condition-inquiry-group-${newGroupCount}`;
        $(this).attr('name', newName);
      });
      $group.find('.condition-select-section').each(function (rowIndex) {
        const $section = $(this);
        const newGroupCount = groupIndex + 1;
        const newRowCount = rowIndex + 1;

        // Update the section names
        updateSectionNames($section, newGroupCount, newRowCount);
      });
    });
  };

  const updateSectionNames = ($section, groupCount, rowCount) => {
    // Update the names inside the section with the new group and row counts

    const $menuItemInput = $section.find('.two-depth-dropdown-menu .dropdown-menu-item .dropdown-menu-item-input');
    $menuItemInput.each(function () {
      $(this).attr('name', `dropdown-menu-item-input-group-${groupCount}-row-${rowCount}`);
    });

    const $subMenuItemInput = $section.find('.two-depth-dropdown-menu .dropdown-menu-sub-item');
    $subMenuItemInput.each(function () {
      $(this).attr('name', `dropdown-menu-sub-item-input-group-${groupCount}-row-${rowCount}`);
    });

    $section.find('.two-depth-dropdown-input').each(function () {
      $(this).attr('name', `email-field-group-${groupCount}-row-${rowCount}`);
    });

    $section.find('.select2.dropdown.two-depth-dropdown-include').each(function () {
      $(this).attr('name', `email-field-include-group-${groupCount}-row-${rowCount}`);
    });

    $section.find('.select2.two-depth-dropdown-include').each(function () {
      $(this).attr('name', `group-field-select-group-${groupCount}-row-${rowCount}`);
    });

    $section.find('.select2.dropdown.two-depth-dropdown-include').each(function () {
      $(this).attr('name', `group-field-agree-group-${groupCount}-row-${rowCount}`);
    });

    $section.find('.select2.dropdown.two-depth-dropdown-include-wide').each(function () {
      $(this).attr('name', `date-choice-section-group-${groupCount}-row-${rowCount}`);
    });

    $section.find('.select2.dropdown.two-depth-dropdown-include').each(function () {
      $(this).attr('name', `date-month-same-section-group-${groupCount}-row-${rowCount}`);
    });

    $section.find('.select2.dropdown.two-depth-dropdown-include').each(function () {
      $(this).attr('name', `date-except-section-month-group-${groupCount}-row-${rowCount}`);
    });

    $section.find('.two-depth-dropdown-input').each(function () {
      $(this).attr('name', `date-except-section-date-group-${groupCount}-row-${rowCount}`);
    });

    $section.find('.datepicker').each(function () {
      $(this).attr('name', `calendar-section-group-${groupCount}-row-${rowCount}`);
    });

    $section.find('.select2.dropdown.two-depth-dropdown-include').each(function () {
      $(this).attr('name', `calendar-section-condition-group-${groupCount}-row-${rowCount}`);
    });

    $section.find('.select2.dropdown.dropdown-activity-options').each(function () {
      $(this).attr('name', `dropdown-activity-condition-group-${groupCount}-row-${rowCount}`);
    });
  };

  const addDeleteButtonListeners = ($button) => {
    $button.on('click', function () {
      const $allModalIndexes = $('.subscriber-filter-modal-index');
      const $containers = $('.condition-select-section');
      const $parentSection = $(this).closest('.condition-select-section');
      const $parentContainer = $(this).closest('.subscriber-filter-modal-index');

      // 첫 번째 항목인 경우
      // 조건 섹션이 여러 개인 경우 섹션만 삭제
      if ($parentContainer.find('.condition-select-section').length > 1) {
        $parentSection.remove();
      } else {
        // 조건 섹션이 하나만 남아 있는 경우 전체 그룹 삭제
        $parentContainer.remove();
      }
      // Update section names after deletion
      updateGroupAndRowCounts();

      updateInquirySelectState();
      updateDeleteButtons(); // 새로운 상태 업데이트
      updateConditionGroupNumbers();
    });
  };

  const addAnotherInquiryButtonListeners = ($button) => {
    $button.on('click', function () {
      closeAllDropdowns();

      addNewInquiryArea($button.closest('.subscriber-filter-modal-index'));
    });
  };

  const addNewInquiryArea = ($parentContainer) => {
    const groupCount = $parentContainer.closest('.subscriber-filter-modal-index').index();
    // Get the current group count
    const rowCount = $parentContainer.find('.condition-select-section').length + 1; // Calculate the new row count

    const $newInquirySection = $(createInquirySection(groupCount, rowCount));

    // Add event listeners to the new section
    const $newDropdownMenu = $newInquirySection.find('.two-depth-dropdown-menu');
    const $newDropdownButton = $newInquirySection.find('.dropdown-toggle');
    const $newCopyButton = $newInquirySection.find('.copy-condition-inquiry');
    const $newDeleteButton = $newInquirySection.find('.delete-condition-inquiry');

    addDropdownListeners($newDropdownMenu, $newDropdownButton);
    addCopyButtonListeners($newCopyButton);
    addDeleteButtonListeners($newDeleteButton);

    $parentContainer.find('.add-another-inquiry-button').before($newInquirySection);
    Utils.setDatepicker();
    updateDeleteButtons();
    updateInquirySelectState();
  };
  const addAnotherGroupInquiryButtonListeners = ($button) => {
    $button.on('click', function () {
      closeAllDropdowns();
      addNewGroupInquiryArea();
    });
  };

  const addNewGroupInquiryArea = () => {
    const groupCount = $('.subscriber-filter-modal-index').length + 1;
    // 새로운 그룹의 번호 설정
    const $newGroup = $(`
      <section class="subscriber-filter-modal-index" style="margin-bottom: 16px;">
        <input type="hidden" name="dropdown-group-inquiry" value="address-filter-group-${groupCount}">
        <div class="inquiry-area">
          <p class="condition-group-inquiry">조건 그룹 ${groupCount}</p>
          <select class="select2 custom-select2 dropdown" data-placeholder="조건 중 하나라도 만족하는(OR)" id="subscriber-filter-condition-inquiry-group-${groupCount}" name="subscriber-filter-condition-inquiry-group-${groupCount}" disabled>
            <option value="subscriber-filter-or" selected data-select2-id="subscriber-filter-condition-or">조건 중 하나라도 만족하는(OR)</option>
            <option value="subscriber-filter-and" data-select2-id="subscriber-filter-condition-and">모든 조건을 만족하는(AND)</option>
          </select>
          <p class="condition-group-inquiry-description">구독자를 조회합니다.</p>
        </div>
        ${createInquirySection(groupCount, 1)}
        <button class="col-5 col-lg-2 add-another-inquiry-button" type="button">
          <i class="material-icons add-icon">add</i>
          <p class="add-another-inquiry-button-description">조건 추가하기</p>
        </button>
      </section>
    `);

    const $newDropdownMenu = $newGroup.find('.two-depth-dropdown-menu');
    const $newDropdownButton = $newGroup.find('.dropdown-toggle');
    const $newCopyButton = $newGroup.find('.copy-condition-inquiry');
    const $newDeleteButton = $newGroup.find('.delete-condition-inquiry');
    const $newAddAnotherInquiryButton = $newGroup.find('.add-another-inquiry-button');

    addDropdownListeners($newDropdownMenu, $newDropdownButton);
    addCopyButtonListeners($newCopyButton);
    addDeleteButtonListeners($newDeleteButton);
    addAnotherInquiryButtonListeners($newAddAnotherInquiryButton);

    updateInquirySelectState();
    updateConditionGroupNumbers();

    $newGroup.find('.select2').select2();
    const $anotherGroupAddSection = $('.another-group-add-section');
    $anotherGroupAddSection.before($newGroup);
    Utils.setDatepicker();
    updateBetweenConditionState();
    updateDeleteButtons();
  };

  const updateDeleteButtons = () => {
    const $allModalIndexes = $('.subscriber-filter-modal-index');
    const $containers = $('.condition-select-section');
    const $deleteButton = $containers.find('.delete-condition-inquiry');
    $allModalIndexes.each(function (index) {
      if (index === 0) {
        $deleteButton.css('display', $containers.length > 1 ? 'block' : 'none');
      } else {
        $deleteButton.css('display', 'block');
      }
    });
  };

  const updateInquirySelectState = () => {
    const $allModalIndexes = $('.subscriber-filter-modal-index');

    $allModalIndexes.each(function (index) {
      var conditionCount = $(this).find('.condition-select-section').length;
      if (conditionCount > 1) {
        $(this).find('[id^="subscriber-filter-condition-inquiry"]').prop('disabled', false);
      } else {
        $(this).find('[id^="subscriber-filter-condition-inquiry"]').prop('disabled', true);
      }
    });

    const modalIndexLength = $('.subscriber-filter-modal-body').find('.subscriber-filter-modal-index').length;
    if (modalIndexLength > 1) {
      $('#subscriber-filter-between-condition-inquiry').prop('disabled', false);
    } else {
      $('#subscriber-filter-between-condition-inquiry').prop('disabled', true);
    }
  };

  const updateConditionGroupNumbers = () => {
    $('.subscriber-filter-modal-index').each(function (groupIndex) {
      $(this)
        .find('.condition-group-inquiry')
        .text(`조건 그룹 ${groupIndex + 1}`);
    });
  };

  const updateBetweenConditionState = () => {
    const $inquiryGroups = $('.subscriber-filter-modal-index');
    $betweenConditionInquiry.prop('disabled', $inquiryGroups.length <= 1);
  };
  closeAllDropdowns();
  selectInitialItem($dropdownMenuTemplate);

  addDropdownListeners($dropdownMenuTemplate, $dropdownButtonTemplate);
  addCopyButtonListeners($copyButtonTemplate);
  addDeleteButtonListeners($('.delete-condition-inquiry'));
  addAnotherInquiryButtonListeners($addAnotherInquiryButtonTemplate);
  addAnotherGroupInquiryButtonListeners($addAnotherGroupInquiryButtonTemplate);

  updateInquirySelectState();
  updateBetweenConditionState();

  // New code to close dropdowns when clicking outside
  $(document).on('click', function (event) {
    if (!$(event.target).closest('.dropdown-container').length) {
      closeAllDropdowns();
    }
  });
}

/**
 * 공통함수
 */
const Utils = (function () {
  let global = {};
  init();

  /**
   * Initialize (Only Once)
   */
  function init() {
    setHeaderEvent();
    setTooltip();
    setTableCheckbox();
  }
  /**
   * Set Header Tab Event For Accessibility
   */

  function setHeaderEvent() {
    const $btnMobileMenu = $('.navbar-nav.mobile a');
    const $mobileMenu = $('.navbar.mobile');
    const $navItem = $('.layout-header .navbar-nav .nav-item');
    const $navLink = $('.navbar-brand, .layout-header .navbar-nav li > a');
    const $toggleItem = $('.toggle-item');

    const $emailToggleItem = $('#emailTable');
    const $addressToggleItem = $('#groupListTable');

    $addressToggleItem.on('click', '.toggle-item', () => {
      const $tableContainer = $addressToggleItem;
      $tableContainer.scrollTop($tableContainer[0].scrollHeight);
    });
    $emailToggleItem.on('click', '.toggle-item', () => {
      const $tableContainer = $emailToggleItem;
      $tableContainer.scrollTop($tableContainer[0].scrollHeight);
    });

    $btnMobileMenu.on('click', () => {
      $('.layout-header').append('<div class="header-backdrop modal-backdrop fade"></div>');
      $mobileMenu.addClass('active');
      $mobileMenu.addClass('visible');
      setTimeout(() => {
        $('.header-backdrop').addClass('show');
      }, 100);
    });
    $(document).on('click', '.header-backdrop, .btn-navbar-close', () => {
      $('.header-backdrop').removeClass('show');
      $mobileMenu.removeClass('active');
      setTimeout(() => {
        $('.header-backdrop').remove();
        $mobileMenu.removeClass('visible');
      }, 300);
    });
    // #endregion mobile menu event
    // #region tab event
    $navItem.on('focusin', function () {
      // removeMenuActive();
      $(this).addClass('active');
    });
    $navItem.on('click', function () {
      $navItem.removeClass('active-only');
      $(this).addClass('active-only');
      $(this).removeClass('active');
    });
    $toggleItem.on('click', function (event) {
      if (!$(event.target).closest('.toggle-area').length) {
        $toggleItem.not(this).removeClass('toggle-active');
        $(this).toggleClass('toggle-active');
        // tbody의 마지막 tr 요소를 찾습니다.
      }
      $(document).on('click', headerToggleEventHandler);
    });

    function headerToggleEventHandler(event) {
      if (!$(event.target).closest('.toggle-item').length && !$(event.target).closest('.toggle-area').length) {
        $toggleItem.removeClass('toggle-active');
        $(document).off('click', headerToggleEventHandler);
      }
    }
    // $navLink.on('focusin', function () {
    //   removeMenuActive();
    // });
    // $(document).on('mousedown', function () {
    //   removeMenuActive();
    // });
    // $('.layout-main, .layout-footer').on('focusin', function () {
    //   removeMenuActive();
    // });
    // function removeMenuActive() {
    //   $('.layout-header .navbar-nav .nav-item').removeClass('active');
    // }
    // #endregion tab event
  }
  /**
   * Set Tooltip
   */
  function setTooltip() {
    $(document).on('mouseenter', '[data-toggle="tooltip"]', function () {
      $(this).tooltip({
        content: function () {
          return $(this).prop('title');
        },
      });
      $(this).tooltip('open');
    });
  }
  /**
   * Set Table Checkbox
   */
  function setTableCheckbox() {
    $(document).on('change', '.ta-table-wrap input.all', (e) => {
      let $tableWrap = $(e.target).parents('.ta-table-wrap');
      let $eachCheckboxList = $tableWrap.find('input.each');
      $eachCheckboxList.prop('checked', $(e.target).prop('checked'));
    });
    $(document).on('change', '.ta-table-wrap input.each', (e) => {
      let $tableWrap = $(e.target).parents('.ta-table-wrap');
      let $allCheckbox = $tableWrap.find('input.all');
      let $eachLength = $tableWrap.find('input.each').length;
      let $eachCheckedLength = $tableWrap.find('input.each:checked').length;
      if ($eachLength == $eachCheckedLength) {
        $allCheckbox.prop('checked', true);
      } else {
        $allCheckbox.prop('checked', false);
      }
    });
  }

  // #region Event
  function onSelect2OpenEvent() {
    // Only Dropdown
    if ($(this).hasClass('dropdown')) {
      $('.select2-dropdown').closest('.select2-container').addClass('dropdown');
    }
    if ($(this).hasClass('custom-select2')) {
      $('.select2-dropdown').closest('.select2-container').addClass('custom-select2');
    }
  }
  function onSelect2SelectEvent() {
    // Multiple Select2 Set Unit
    if ($(this).attr('multiple') == 'multiple') {
      let $options = $('.select2-selection--multiple .select2-selection__choice');
      if ($(this).data('unit')) {
        $options.attr('data-unit', $(this).data('unit'));
      } else {
        $options.attr('data-unit', '개 항목');
      }
    }
  }
  // #endregion Event

  return {
    // [컴포넌트] 값 변경시 해당 위젯에 적용하는 함수
    templateUpdateValue(key, box) {
      let newValue; // 값을 저장할 변수를 추가

      switch (key) {
        case 'textBackground':
          newValue = rgbToHex($('#textBackground').val());
          box.find('.editor-text').css('background-color', newValue);
          Utils.applyColorStyle($('#textBackground'), newValue);
          break;

        case 'textBorderColor':
          newValue = rgbToHex($('#textBorderColor').val());
          box.find('.editor-text').css('border-color', newValue);
          Utils.applyColorStyle($('#textBorderColor'), newValue);
          break;

        case 'textBorderSize':
          newValue = $('#textBorderSize').val();
          box.find('.editor-text').css('border-width', newValue).css('border-style', 'solid');
          break;

        case 'textInnerTopPadding':
          newValue = $('#textInnerTopPadding').val();
          box.find('.editor-text').css('padding-top', newValue);
          break;

        case 'textInnerBottomPadding':
          newValue = $('#textInnerBottomPadding').val();
          box.find('.editor-text').css('padding-bottom', newValue);
          break;

        case 'textInnerHorizontalPadding':
          newValue = $('#textInnerHorizontalPadding').val();
          box.find('.editor-text').css('padding-left', newValue).css('padding-right', newValue);
          break;
        case 'webTextBackground':
          newValue = rgbToHex($('#webTextBackground').val());
          box.find('.editor-web-text').css('background-color', newValue);
          Utils.applyColorStyle($('#webTextBackground'), newValue);
          break;

        case 'webTextBorderColor':
          newValue = rgbToHex($('#webTextBorderColor').val());
          box.find('.editor-web-text').css('border-color', newValue);
          Utils.applyColorStyle($('#webTextBorderColor'), newValue);
          break;

        case 'webTextBorderSize':
          newValue = $('#webTextBorderSize').val();
          box.find('.editor-web-text').css('border-width', newValue).css('border-style', 'solid');
          break;

        case 'webTextInnerTopPadding':
          newValue = $('#webTextInnerTopPadding').val();
          box.find('.editor-web-text').css('padding-top', newValue);
          break;

        case 'webTextInnerBottomPadding':
          newValue = $('#webTextInnerBottomPadding').val();
          box.find('.editor-web-text').css('padding-bottom', newValue);
          break;

        case 'webTextInnerHorizontalPadding':
          newValue = $('#webTextInnerHorizontalPadding').val();
          box.find('.editor-web-text').css('padding-left', newValue).css('padding-right', newValue);
          break;
        case 'buttonBackground':
          newValue = rgbToHex($('#buttonBackground').val());
          box.find('.box-row.button').css('background-color', newValue);
          Utils.applyColorStyle($('#buttonBackground'), newValue);
          break;

        case 'buttonBorderSize':
          newValue = $('#buttonBorderSize').val();
          box.find('.box-row.button').css('border-width', newValue).css('border-style', 'solid');
          break;

        case 'buttonInnerTopPadding':
          newValue = $('#buttonInnerTopPadding').val();
          box.find('.box-row.button').css('padding-top', newValue);
          break;

        case 'buttonInnerBottomPadding':
          newValue = $('#buttonInnerBottomPadding').val();
          box.find('.box-row.button').css('padding-bottom', newValue);
          break;

        case 'buttonHorizontalPadding':
          newValue = $('#buttonHorizontalPadding').val();
          box.find('.box-row.button').css('padding-left', newValue).css('padding-right', newValue);
          break;

        case 'buttonBorderColor':
          newValue = rgbToHex($('#buttonBorderColor').val());
          box.find('.box-row.button').css('border-color', newValue);
          Utils.applyColorStyle($('#buttonBorderColor'), newValue);
          break;

        case 'blankBorderSize':
          newValue = $('#blankBorderSize').val();
          box.find('.box-row.blank').css('border-width', newValue).css('border-style', 'solid');
          break;

        case 'blankHeightSlider':
          newValue = $('#blankHeightSlider').val();
          box.find('.box-row.blank').css('height', newValue);

          break;

        case 'blankBackground':
          newValue = rgbToHex($('#blankBackground').val());
          box.find('.box-row.blank').css('background-color', newValue);
          Utils.applyColorStyle($('#blankBackground'), newValue);
          break;

        case 'blankBorderColor':
          newValue = rgbToHex($('#blankBorderColor').val());
          box.find('.box-row.blank').css('border-color', newValue).css('border-style', 'solid');
          Utils.applyColorStyle($('#blankBorderColor'), newValue);
          break;

        case 'footerBorderSize':
          newValue = $('#footerBorderSize').val();
          box.find('.box-row.footer').css('border-width', newValue).css('border-style', 'solid');
          break;

        case 'footerInnerTopPadding':
          newValue = $('#footerInnerTopPadding').val();
          box.find('.box-row.footer').css('padding-top', newValue);
          break;

        case 'footerInnerBottomPadding':
          newValue = $('#footerInnerBottomPadding').val();
          box.find('.box-row.footer').css('padding-bottom', newValue);
          break;

        case 'footerInnerHorizontalPadding':
          newValue = $('#footerInnerHorizontalPadding').val();
          box.find('.box-row.footer').css('padding-left', newValue).css('padding-right', newValue);
          break;

        case 'footerBackground':
          newValue = rgbToHex($('#footerBackground').val());
          box.find('.box-row.footer').css('background-color', newValue);
          Utils.applyColorStyle($('#footerBackground'), newValue);
          break;

        case 'footerBorderColor':
          newValue = rgbToHex($('#footerBorderColor').val());
          box.find('.box-row.footer').css('border-color', newValue);
          Utils.applyColorStyle($('#footerBorderColor'), newValue);
          break;

        case 'dividerBorderColor':
          newValue = rgbToHex($('#dividerBorderColor').val());
          box.find('.box-row.divider').css('border-color', newValue);
          Utils.applyColorStyle($('#dividerBorderColor'), newValue);
          break;

        case 'dividerBorderSize':
          newValue = $('#dividerBorderSize').val();
          box.find('.box-row.divider').css('border-width', newValue).css('border-style', 'solid');
          break;

        case 'dividerInnerTopPadding':
          newValue = $('#dividerInnerTopPadding').val();
          box.find('.box-row.divider').css('padding-top', newValue);
          break;

        case 'dividerInnerBottomPadding':
          newValue = $('#dividerInnerBottomPadding').val();
          box.find('.box-row.divider').css('padding-bottom', newValue);
          break;

        case 'dividerHorizontalPadding':
          newValue = $('#dividerHorizontalPadding').val();
          box.find('.box-row.divider').css('padding-left', newValue).css('padding-right', newValue);
          break;

        case 'dividerBackground':
          newValue = rgbToHex($('#dividerBackground').val());
          box.find('.box-row.divider').css('background-color', newValue);
          Utils.applyColorStyle($('#dividerBackground'), newValue);
          break;

        case 'layoutOneBorderSize':
          newValue = $('#layoutOneBorderSize').val();
          box.find('.box-row.layout-one').css('border-width', newValue).css('border-style', 'solid');
          break;

        case 'layoutOneInnerTopPadding':
          newValue = $('#layoutOneInnerTopPadding').val();
          box.find('.box-row.layout-one').css('padding-top', newValue);
          break;

        case 'layoutOneInnerBottomPadding':
          newValue = $('#layoutOneInnerBottomPadding').val();
          box.find('.box-row.layout-one').css('padding-bottom', newValue);
          break;

        case 'layoutOneHorizontalPadding':
          newValue = $('#layoutOneHorizontalPadding').val();
          box.find('.box-row.layout-one').css('padding-left', newValue).css('padding-right', newValue);
          break;

        case 'layoutOneBackground':
          newValue = rgbToHex($('#layoutOneBackground').val());
          box.find('.box-row.layout-one').css('background-color', newValue);
          Utils.applyColorStyle($('#layoutOneBackground'), newValue);
          break;

        case 'layoutOneColor':
          newValue = rgbToHex($('#layoutOneColor').val());
          box.find('.box-row.layout-one').css('border-color', newValue);
          Utils.applyColorStyle($('#layoutOneColor'), newValue);
          break;
      }
      if (!key.includes('Background') && !key.includes('BorderColor')) {
        $('.editing-container').trigger('editorSave'); // 중간 저장
      } else {
        Utils.emailColorChangeSave();
      }
      // extractedValuesCopy에 새로운 값을 저장
      extractedValuesCopy[key] = newValue;
      console.log(`key: ${key} value: ${extractedValuesCopy[key]}`);
    },
    emailColorChangeSave() {
      if (global['colorTimeout'] && global['colorTimeout'] != null) {
        clearTimeout(global['colorTimeout']);
      }

      global['colorTimeout'] = setTimeout(() => {
        $('.editing-container').trigger('editorSave'); // 중간 저장
        clearTimeout(global['colorTimeout']);
        global['colorTimeout'] = null;
      }, 2000);
    },
    /**
     * Show Toast
     * @param {String} type     // info, success, warning, danger
     * @param {String} message
     */
    showToast(type, message) {
      const DELAY_TIME = 3000;
      const HIDE_TIME = 600;
      const fnShowToast = () => {
        $('body').append(`
        <div class="toast-container ${type} opacity-0">
          <div class="toast-message">${message}</div>
        </div>
        `);
        $('.toast-container').css('--height', $('.toast-container').outerHeight());
        $('.toast-container').removeClass('opacity-0');

        global['toastMainTimeout'] = setTimeout(() => {
          $('.toast-container').css('--height', $('.toast-container').outerHeight());
          $('.toast-container').addClass('hide');

          global['toastSubTimeout'] = setTimeout(() => {
            $('.toast-container').remove();
          }, HIDE_TIME);
        }, DELAY_TIME - HIDE_TIME);
      };

      if ($('.toast-container').length == 0) {
        fnShowToast();
      } else {
        $('.toast-container').css('--height', $('.toast-container').outerHeight());
        $('.toast-container').addClass('hide');

        setTimeout(() => {
          clearTimeout(global['toastMainTimeout']);
          clearTimeout(global['toastSubTimeout']);

          $('.toast-container').remove();

          fnShowToast();
        }, 600);
      }
    },
    /**
     * Set dateRangepicker
     */
    /**
     * Set echarts
     */
    setEmailChart(id, dataset) {
      let emailChart;

      let newData = {
        grid: {
          left: '0%', // Add this line
          right: '0%', // Add this line
          top: '20%', // Add this line
          bottom: '0%', // Add this line
          containLabel: true, // Add this line
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          data: dataset['label'],
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          axisLabel: {
            show: true,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed', // Add this line
            },
          },
        },
        series: [
          {
            name: '오픈',
            type: 'line', // 막대 그래프
            data: dataset['openData'],
            barWidth: '50%', // Add this line
            label: {
              show: false,
              position: 'top',
            },
            itemStyle: {
              color: '#ffc800',
            },
            symbolSize: 10,
            symbol: 'circle',
          },
          {
            name: '클릭',
            type: 'line', // 막대 그래프
            data: dataset['clickData'],
            barWidth: '50%', // Add this line
            label: {
              show: false,
              position: 'top',
            },
            itemStyle: {
              color: '#3e81f6',
            },
            symbolSize: 10,
            symbol: 'circle',
          },
        ],
      };
      emailChart = echarts.init(document.getElementById(id));
      emailChart.setOption(newData);
      window.onresize = function () {
        emailChart.resize();
      };
    },

    setDashboardChart(id, dataset) {
      let dashboardChart;

      let newData = {
        grid: {
          left: '0%', // Add this line
          right: '0%', // Add this line
          top: '20%', // Add this line
          bottom: '0%', // Add this line
          containLabel: true, // Add this line
        },
        xAxis: {
          data: dataset['label'],
          axisTick: {
            show: false, // Add this line
          },
        },
        yAxis: {
          axisLabel: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed', // Add this line
            },
          },
        },
        series: [
          {
            name: '개수',
            type: 'bar', // 막대 그래프
            data: dataset['data'],
            barWidth: '50%', // Add this line
            label: {
              show: true,
              position: 'top',
            },
            itemStyle: {
              color: '#22E5C9',
            },
          },
        ],
      };
      dashboardChart = echarts.init(document.getElementById(id));
      dashboardChart.setOption(newData);
      window.onresize = function () {
        dashboardChart.resize();
      };
    },
    /**
     * Set Datepicker
     */
    setDatepicker() {
      let $datepicker = $('.datepicker');
      let today = new Date();
      let formattedDate = $.datepicker.formatDate('yy-mm-dd', today);

      $datepicker.datepicker({
        dateFormat: 'yy-mm-dd',
        // modal에서 position 틀어지는 부분 잡기
        beforeShow: function (input, inst) {
          const $modal = $(input).closest('.modal');
          if ($modal.length > 0) {
            $modal.append($('#ui-datepicker-div'));
          }

          var calendar = inst.dpDiv;

          setTimeout(function () {
            calendar.position({
              my: 'right top',
              at: 'right bottom',
              collision: 'none',
              of: input,
            });
          }, 1);
        },
      });
      $datepicker.datepicker().datepicker('setDate', 'today');

      // 최소, 최대 일자 선택
      $('.start .datepicker').on('change', function () {
        let $parent = $(this).closest('.ta-date-pair-wrap');
        let $endDate = $parent.find('.end .datepicker');
        let val = $(this).val();
        $endDate.datepicker('option', 'minDate', val);
      });

      $('.end .datepicker').on('change', function () {
        let $parent = $(this).closest('.ta-date-pair-wrap');
        let $startDate = $parent.find('.start .datepicker');
        let val = $(this).val();
        $startDate.datepicker('option', 'maxDate', val);
      });
      $datepicker.trigger('change');

      // daterangepicker
      let $daterangepicker = $('.custom-daterangepicker');
      $daterangepicker.each(function () {
        const d = new Date();

        // 오늘날의 년, 월, 일 데이터
        const day = d.getDate();
        const month = d.getMonth();
        const weekAgo = new Date(new Date().setDate(day - 7));
        const monthAgo = new Date(new Date().setMonth(month - 1));

        $(this).daterangepicker(
          {
            opens: 'right',
            locale: {
              format: 'YYYY-MM-DD',
              separator: ' ~ ',
              applyLabel: '검색하기',
              cancelLabel: '취소',
              fromLabel: '시작일자',
              toLabel: '종료일자',
              customRangeLabel: '직접선택',
              daysOfWeek: ['일', '월', '화', '수', '목', '금', '토'],
              monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
              firstDay: 0,
            },
            ranges: {
              '전체 기간': [moment().subtract(10, 'years'), moment().add(10, 'years')],
              오늘: [moment(), moment()],
              '지난 7일': [moment().subtract(6, 'days'), moment()],
              '지난 30일': [moment().subtract(29, 'days'), moment()],
              '이번 달': [moment().startOf('month'), moment().endOf('month')],
              '지난 달': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
          },
          function (start, end, label) {
            // console.log('새로운 날짜 선택이 이루어졌습니다: ' + start.format('YYYY-MM-DD') + ' ~ ' + end.format('YYYY-MM-DD');
          },
        );

        $(this).on('apply.daterangepicker', function (ev, picker) {
          $(this).val(picker.startDate.format('YYYY-MM-DD') + ' ~ ' + picker.endDate.format('YYYY-MM-DD'));
        });
        $(this).on('apply.daterangepicker', function (ev, picker) {
          var startDate = picker.startDate.format('YYYY-MM-DD');
          var endDate = picker.endDate.format('YYYY-MM-DD');
          $(this).val(startDate + ' ~ ' + endDate);
          $('.custom-daterangepicker-result').text(startDate + ' ~ ' + endDate); // 선택한 날짜 범위를 <span> 요소의 텍스트로 설정
        });
        $(this).on('focus', function () {
          $('[data-range-key="Custom Range"]').text('맞춤');
        });
        if ($(this).parents('.list-control').length > 0) {
          // 목록 필터영역일 경우 default가 오늘
          $(this).data('daterangepicker').setStartDate(new Date());
          $(document).on(
            'click',
            `
            .daterangepicker .ranges ul li,
            .table-condensed th.prev,
            .table-condensed th.next,
            .daterangepicker .drp-buttons .cancelBtn,
            .daterangepicker .drp-buttons .applyBtn`,
            function (e) {
              e.stopPropagation();
            },
          );
        } else {
          // 나머지 daterangepicker default는 한달전
          $(this).data('daterangepicker').setStartDate(monthAgo);
          $(this).val(Utils.dateFormatter(monthAgo).dash + ' ~ ' + Utils.dateFormatter(new Date()).dash);
        }
      });
    },
    /**
     * Set Validation
     */
    setValidation() {
      let elements = document.querySelectorAll('input, textarea');
      for (let i = 0; i < elements.length; i++) {
        elements[i].oninvalid = function (e) {
          e.target.setCustomValidity(' ');
          if (!e.target.validity.valid) {
            elements[i].classList.add('is-invalid');
          }
        };
        elements[i].oninput = function (e) {
          e.target.setCustomValidity(' ');
        };
        if ($(elements[i]).attr('required')) {
          elements[i].focusout = () => {
            // elements[i].classList.remove('is-valid');
            elements[i].classList.remove('is-invalid');
            if (elements[i].value == null || elements[i].value == '') {
              elements[i].classList.add('is-invalid');
            } else {
              // elements[i].classList.add('is-valid');
            }
          };
        }
      }
    },
    /**
     * Set Select2
     */
    setSelect2() {
      const $select2List = $('select.select2');
      $select2List.each(function () {
        let options = {
          allowClear: true,
          language: {
            noResults: () => {
              if ($(this).attr('data-search-placeholder')) {
                return $(this).attr('data-search-placeholder');
              } else {
                return '검색 결과가 없습니다.';
              }
            },
          },
        };
        $(this).select2(options);

        // mutilple select2 search input default is disabled
        setTimeout(() => {
          if ($(this).attr('multiple') == 'multiple') {
            const $select2Search = $('.select2-selection--multiple .select2-search');
            $select2Search.find('input').attr('disabled', true);
          }
        }, 0);
      });

      $select2List.off('select2:open', onSelect2OpenEvent);
      $select2List.off('select2:select', onSelect2SelectEvent);
      $select2List.on('select2:open', onSelect2OpenEvent);
      $select2List.on('select2:select', onSelect2SelectEvent);
    },

    /**
     * Set Custom Select
     * if Change Select Call setCustomDropdownSelect(id);
     * @param {String} tagId
     */
    setCustomDropdownSelect(tagId = null) {
      const createCustomDropdownSelect = function (el) {
        let $originalSelect = $(el);
        let $originalOptgroupList = $originalSelect.find('optgroup');
        let $originalOptionList = $originalSelect.find('option');
        let placeholder = '선택';
        let isPlaceholder = false;
        if ($(el).parent().find('.custom-dropdown-select').length <= 0) {
          let newSelect = `
            <div class="dropdown custom-dropdown-select">
            <button class="btn dropdown-toggle" type="button" id="${$originalSelect.attr('id')}Dropdown" ${
            $originalSelect.attr('disabled') ? 'tabindex="-1"' : ''
          } data-toggle="dropdown" aria-expanded="false">

            ${placeholder}
            </button>
            <div class="dropdown-menu custom-dropdown-select" aria-labelledby="${$originalSelect.attr('id')}Dropdown">
            `;
          if ($originalOptgroupList.length > 0) {
            for (let i = 0; i < $originalOptgroupList.length; i++) {
              $originalOptionList = $originalOptgroupList.eq(i).find('option');
              newSelect += `
                <div class="dropdown-optgroup">
                <h6>${$originalOptgroupList[i].label}</h6>
                `;
              for (let j = 0; j < $originalOptionList.length; j++) {
                if ($originalOptionList[j].value == '') {
                  isPlaceholder = true;
                  placeholder = $originalOptionList[j].textContent;
                } else {
                  newSelect += `
                    <a href="javascript:void(0)"
                    class="dropdown-item ${$($originalOptionList[j]).prop('selected') ? 'active' : ''}"
                    data-value="${$originalOptionList[j].value}"
                    data-toggle="tooltip" data-placement="bottom"
                    title="${$originalOptionList[j].title}">
                    ${$originalOptionList[j].text}
                    </a>
                    `;
                }
              }
              newSelect += '</div>';
            }
          } else {
            for (let i = 0; i < $originalOptionList.length; i++) {
              if ($originalOptionList[i].value == '') {
                isPlaceholder = true;
                placeholder = $originalOptionList[i].textContent;
              } else {
                newSelect += `
                  <a href="javascript:void(0)"
                  class="dropdown-item ${$($originalOptionList[i]).prop('selected') ? 'active' : ''}"
                  data-toggle="tooltip" data-placement="bottom"
                  data-value="${$originalOptionList[i].value}"
                  title="${$originalOptionList[i].title}">
                  ${$originalOptionList[i].text}</a>
                  `;
              }
            }
          }
          newSelect += `
            </div>
            </div>
            `;
          $originalSelect.after(newSelect);
          $originalSelect.next().find('.btn').text(placeholder);

          if ($(el).parent().find('a.dropdown-item.active').length > 0) {
            $('#' + $originalSelect.attr('id') + 'Dropdown').text($(el).parent().find('a.dropdown-item.active').text());
          } else {
            if (isPlaceholder) {
              $(el).parent().find('.custom-dropdown-select').addClass('dropdown-placeholder');
            }
          }
        }
      };
      if (tagId == null) {
        let $customSelect = $('.ta-custom-dropdown-select-wrap select:not(:disabled)');
        $(document).off('click', '.custom-dropdown-select .dropdown-item');
        $(document).on('click', '.custom-dropdown-select .dropdown-item', function () {
          let $this = $(this);
          setTimeout(function () {
            let $originalSelect = $this.parents('.ta-custom-dropdown-select-wrap').find('select');
            let $originalOptionList = $originalSelect.find('option:not([value=""]');
            let $index = $this.parents('.dropdown-menu').find('.dropdown-item').index($this);
            let $btn = $this.parents('.custom-dropdown-select').find('.btn');
            $originalOptionList.eq($index).prop('selected', true);
            $originalSelect.trigger('change');
            $btn.text($this.text());
            $btn.parent().removeClass('dropdown-placeholder');
            $this.parents('.dropdown-menu').find('.dropdown-item').removeClass('active');
            $this.addClass('active');
            $originalSelect.removeClass('is-invalid');
            $originalSelect.closest('div').parent().find('.invalid-feedback').remove();
          }, 0);
        });
        $customSelect.each((index, el) => {
          createCustomDropdownSelect(el);
        });
      } else {
        let $originalSelect = $(`#${tagId}`);
        $originalSelect.parent().find('.custom-dropdown-select').remove();
        createCustomDropdownSelect($(`#${tagId}`)[0]);
      }
    },
    /**
     * Get Global Variable
     */
    getGlobal() {
      return global;
    },
    /**
     * Get CSS Breakpoint
     */
    getBreakpoint() {
      let breakpoint = getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content');
      breakpoint = breakpoint.replaceAll('"', '');
      return breakpoint;
    },
    /**
     * Get input[type=file] detail
     * @param {Element} elmnt
     * @returns {Object}
     */
    getFileDetail(elmnt) {
      //파일 경로.
      let filePath = elmnt.value;
      //전체경로를 \ 나눔.
      let filePathSplit = filePath.split('\\');
      // 파일 전체명
      let originalFileName = filePathSplit[filePathSplit.length - 1];
      //파일확장자 앞 .의 index
      let lastDot = originalFileName.lastIndexOf('.');
      //파일명 : .으로 나눈 앞부분
      let fileName = originalFileName.substring(0, lastDot);
      //파일 확장자 : .으로 나눈 뒷부분
      let fileExt = originalFileName.substring(lastDot + 1, originalFileName.length).toLowerCase();
      //파일 크기
      let fileSize = filePath != '' ? elmnt.files[0].size : null;

      let object = {
        originalName: originalFileName,
        name: fileName,
        ext: fileExt,
        size: fileSize,
      };

      return object;
    },
    /**
     * Start Loading
     */
    startLoading() {
      let wrapElmnt = document.querySelector('.spinner-wrap');

      if (wrapElmnt) {
        wrapElmnt.dataset.task = Number(wrapElmnt.dataset.task) + 1;
      } else {
        wrapElmnt = document.createElement('div');
        wrapElmnt.classList.add('spinner-wrap');
        wrapElmnt.dataset.task = 1;
        let loadingElmnt = document.createElement('div');
        loadingElmnt.classList.add('spinner-border');
        let span = document.createElement('span');
        span.classList.add('sr-only');
        span.innerHTML = 'Loading...';
        loadingElmnt.appendChild(span);
        wrapElmnt.appendChild(loadingElmnt);

        let body = document.getElementsByTagName('body')[0];
        body.appendChild(wrapElmnt);
      }
    },
    /**
     * End Loading
     */
    endLoading() {
      setTimeout(function () {
        let wrapElmnt = document.querySelector('.spinner-wrap');

        if (wrapElmnt) {
          let taskCount = wrapElmnt.dataset.task;

          if (taskCount > 1) {
            wrapElmnt.dataset.task = Number(wrapElmnt.dataset.task) - 1;
          } else {
            wrapElmnt.style.opacity = 0;
            setTimeout(function () {
              wrapElmnt.remove();
            }, 300);
            let body = document.getElementsByTagName('body')[0];
          }
        }
      }, 300);
    },
    /**
     * Number to KRW format
     * ex) 1000000 -> 1,000,000
     * @param {Number} value
     * @returns {String}
     */
    numberFormatter(value) {
      if (value != '' && value != null && typeof value == 'number') {
        value = String(value)
          .replace(/[^\d]+/g, '')
          .replace(/(^0+)/, '')
          .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
      } else {
        value = 0;
      }
      return value === '' ? 'NaN' : value;
    },
    /**
     * Byte to size
     * return ex) 5 GB
     * @param {Number} byte
     * @returns {String}
     */
    byteFormatter(byte) {
      let sizes = ['Byte', 'KB', 'MB', 'GB', 'TB'];
      if (byte == 0) return '0 Byte';
      let i = parseInt(Math.floor(Math.log(byte) / Math.log(1024)));
      return Math.round(byte / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },
    /**
     * Set date format
     * @param {String} date
     * @returns {Object}
     */
    dateFormatter(date) {
      if ((date == '' || date == null) && typeof date == 'string') {
        return '';
      }
      const addZero = (num, digits) => {
        var zero = '';
        num = num.toString();

        if (num.length < digits) {
          for (var i = 0; i < digits - num.length; i++) {
            zero += '0';
          }
        }
        return zero + num;
      };
      // Safari Invalid Date 로 인한 replace
      // date = date.substring(0, date.lastIndexOf('.')).replace(/-/g, '/').replace('T', ' ');
      let newDate = new Date(date);

      let yyyy = newDate.getFullYear();
      let mm = addZero(newDate.getMonth() + 1, 2);
      let m = newDate.getMonth() + 1;
      let dd = addZero(newDate.getDate(), 2);
      let d = newDate.getDate();

      let object = {
        slash: yyyy + '/' + mm + '/' + dd,
        dot: yyyy + '.' + mm + '.' + dd,
        dash: yyyy + '-' + mm + '-' + dd,
        word: yyyy + '년 ' + m + '월 ' + d + '일',
      };

      return object;
    },

    setAddressListSectionDropdownAddressItemData: function (className, dataset, selectedAddressId) {
      var html = '';
      var flag = false;

      for (var i = 0; i < dataset.addressList.length; i++) {
        var item = dataset.addressList[i];
        var isSelected = item.addressId == selectedAddressId ? ' selected' : '';

        html += '<div class="address-card" data-address-id="' + item.addressId + '">';
        html += '<a class="dropdown-item email-main-address-search-output' + isSelected + '" href="javascript:void(0)">';
        html += item.addressName;
        html += '<p class="subscriber">' + item.subscriberCount + '</p>';
        html += '</a>';
        html += '</div>';

        if (item.addressId == selectedAddressId) {
          var span = $('.email-main-address-name span');
          var input = $('input[name="selectedAddress"]');

          span.text(item.addressName);
          input.val(item.addressId);

          flag = true;
        }
      }
      $('.' + className).html(html);
      return flag;
    },
    setAddressListSectionDropdownGroupItemData: function (className, dataset) {
      var selectedCheckboxes = {}; // 선택된 체크박스 상태 저장
      $('input[type="checkbox"].email-main-address-segment-output:checked').each(function () {
        selectedCheckboxes[$(this).attr('id')] = true;
      });

      var html = '';

      // First group
      for (var i = 0; i < dataset.firstGroup.length; i++) {
        var item = dataset.firstGroup[i];
        html += '<a class="dropdown-item ta-checkbox-wrap" href="javascript:void(0)" data-group=0>';
        html += '<input type="checkbox" name="" id="checkbox_' + (i + 1) + '" data-group=0 class="sr-only each email-main-address-segment-output" />';
        html += '<label for="checkbox_' + (i + 1) + '">' + item.displayName + '</label>';
        html += '</a>';
      }

      // Add inner text between the groups
      html += '<p class="inner-text">그룹</p>';

      // Second group
      for (var i = 0; i < dataset.secondGroup.length; i++) {
        var item = dataset.secondGroup[i];
        html += '<a class="dropdown-item ta-checkbox-wrap" href="javascript:void(0)" data-group=' + item.groupId + '>';
        html +=
          '<input type="checkbox" name="" id="checkbox_' +
          (i + dataset.firstGroup.length + 1) +
          '" data-group=' +
          item.groupId +
          ' class="sr-only each email-main-address-segment-output segment-space-between" />';
        html += '<label for="checkbox_' + (i + dataset.firstGroup.length + 1) + '">' + item.displayName + '</label>';
        html += '<span class="popular">' + item.subscriberCount + '</span>';
        html += '</a>';
      }

      $('.' + className).html(html);

      // 상태 복구
      for (var checkboxId in selectedCheckboxes) {
        $('#' + checkboxId).prop('checked', true);
      }
    },

    setEmailSendInfoSectionDropdownItemData: function (className, dataset) {
      var html = '';
      html += '<p>주소록 필드</p>';

      for (var i = 0; i < dataset.addressFieldList.length; i++) {
        var item = dataset.addressFieldList[i];
        html += '<a data-value="' + item.dataValue + '" class="dropdown-item email-main-send-info-code-dropdown-item" id="' + item.id + '" href="javascript:void(0)">' + item.displayName + '</a>';
      }

      html += '<p>사용자 정의 필드</p>';
      for (var i = 0; i < dataset.userCustomFieldList.length; i++) {
        var item = dataset.userCustomFieldList[i];
        html += '<a data-value="' + item.dataValue + '" class="dropdown-item email-main-send-info-code-dropdown-item" id="' + item.id + '" href="javascript:void(0)">' + item.displayName + '</a>';
      }

      $('.' + className).html(html);
    },

    setEmailSenderEmailSectionRadioItemData: function (className, dataset) {
      var selectedRadioValue = $('input[name="radio"]:checked').val(); // 선택된 라디오 값 저장
      var html = '';
      for (var i = 0; i < dataset.length; i++) {
        var item = dataset[i];
        html += '<div class="ta-radio-wrap radio-element">';
        html += '<input type="radio" value="' + item.id + '" name="radio" id="radio_' + (i + 1) + '" class="sr-only"/>';
        html += '<label for="radio_' + (i + 1) + '">' + item.email + '</label>';
        html += '</div>';
      }
      $('.' + className).html(html);

      // 상태 복구
      if (selectedRadioValue) {
        $('input[name="radio"][value="' + selectedRadioValue + '"]').prop('checked', true);
      }
    },

    //인코레터 공유 URL SCHEME

    shareURLScheme() {
      var url = window.location.href;
      var urlObj = new URL(url);

      $('#kakaoButton').on('click', function (event) {
        event.stopPropagation(); // 이벤트 전파 막기

        var url = window.location.href;
        var urlObj = new URL(url);

        Kakao.Share.sendScrap({
          requestUrl: `${urlObj.href}`,
          templateId: 111131, // 템플릿 ID를 설정해 주십시오 (필요 시)
        });
      });

      $('#facebookButton').on('click', function (event) {
        event.stopPropagation(); // 이벤트 전파 막기

        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlObj.href)}`;

        window.open(facebookShareUrl, '_blank', 'width=800,height=420');
      });

      // NOTE: 추후 URL 주소가 나오면 변경해야함.
      $('#copyButton').on('click', function (event) {
        event.stopPropagation(); // 이벤트 전파 막기

        // Clipboard API를 사용하여 URL 복사
        if (navigator.clipboard) {
          navigator.clipboard
            .writeText(urlObj.href)
            .then(function () {
              Utils.showToast('success', 'URL이 복사되었습니다.');
            })
            .catch(function (error) {
              console.error('Failed to copy text: ', error);
              Utils.showToast('danger', 'URL이 복사되지 않았습니다.');
            });
        } else {
          // Clipboard API를 지원하지 않는 경우 execCommand를 사용
          const tempTextarea = $('<textarea>');
          tempTextarea.val(urlObj.href);
          tempTextarea.css({
            position: 'fixed',
            top: 0,
            left: 0,
            width: '1px',
            height: '1px',
            padding: 0,
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            background: 'transparent',
          });
          tempTextarea.prop('readonly', true);
          $('body').append(tempTextarea);
          tempTextarea.select();
          try {
            document.execCommand('copy');
            Utils.showToast('success', 'URL이 복사되었습니다.');
          } catch (err) {
            Utils.showToast('danger', 'URL이 복사되지 않았습니다.');
          }
          tempTextarea.remove();
        }
      });
    },
    //저장하기들 버튼 클릭 이벤트 생성
    validateModalFields($modal) {
      var isValid = true;
      let firstInvalidInput = null;

      // 모든 input과 select 요소를 찾습니다.
      var $inputs = $modal.find('input');
      var $selects = $modal.find('select');

      // 각 input 필드를 검사합니다.
      $inputs.each(function () {
        var $input = $(this);
        var $feedback = $input.siblings('.invalid-feedback');

        // 기본값 설정 필드의 경우 검사 X
        if ($input.attr('id') === 'user-custom-modal-field-mail-merge' || $input.attr('id') === 'user-custom-modal-add-field-mail-merge') {
          return true;
        }

        if ($input.attr('type') !== 'checkbox' && $.trim($input.val()) === '') {
          isValid = false;
          $input.addClass('is-invalid');
          if ($feedback.length) {
            $feedback.show();
          }
        } else {
          $input.removeClass('is-invalid');
          if ($feedback.length) {
            $feedback.hide();
          }
        }
      });

      // 각 select 필드를 검사합니다.
      $selects.each(function () {
        var $select = $(this);
        var $feedback = $select.siblings('.invalid-feedback');

        if ($.trim($select.val()) === '') {
          isValid = false;
          $select.addClass('is-invalid');
          if ($feedback.length) {
            $feedback.show();
            if (!isValid) {
              firstInvalidInput = $(this);
            }
          }
        } else {
          $select.removeClass('is-invalid');
          if ($feedback.length) {
            $feedback.hide();
          }
        }
      });

      // 첫 번째 비어 있는 입력 필드로 스크롤
      if (firstInvalidInput) {
        $('html, body').animate(
          {
            scrollTop: firstInvalidInput.offset().top - $(window).height() / 2 + firstInvalidInput.height() / 2,
          },
          'smooth',
        );
      }

      return isValid;
    },

    validateDirectInputGroup($modal) {
      var isRadio1Selected = $('#direct-input-group-radio_1').is(':checked');

      if (isRadio1Selected) {
        // file-check-group-cell 요소가 존재하는지 확인
        var $fileCheckGroupCell = $('.file-check-group-cell');

        if ($fileCheckGroupCell.length === 0) {
          return false; // file-check-group-cell이 없으면 false 반환
        } else {
          var $checkboxes = $fileCheckGroupCell.find('.direct-input-checkbox');
          var isAnyChecked = false;

          $checkboxes.each(function () {
            if ($(this).is(':checked')) {
              isAnyChecked = true;
              return false; // 체크된 항목을 발견하면 반복을 중단합니다.
            }
          });

          return isAnyChecked;
        }
      }

      return true; // 조건을 모두 충족하거나 radio_1이 선택되지 않은 경우 true 반환
    },
    //이메일 콘텐츠 이미지 입력

    handleImageUpload(input) {
      // input 요소가 유효한지 확인
      if (!input || !input.files || input.files.length === 0) {
        Utils.showToast('danger', '파일이 선택되지 않았습니다.');
        return;
      }

      const file = input.files[0];
      if (file && file.type === 'image/png') {
        // Blob URL 생성
        const imageUrl = URL.createObjectURL(file);

        // 이미지 태그를 생성하여 표시
        const imageHtml = `
          <img src="${imageUrl}" alt="첨부된 이미지" style="width: 100%; height: 100%; object-fit: contain;" />
        `;

        // editor-image div 내부를 업로드된 이미지로 대체
        const $imageContainer = $(input).closest('.editor-image');
        $imageContainer.html(imageHtml);

        // 이미지가 로드된 후 Blob URL을 해제
        $imageContainer.find('img').on('load', function () {
          URL.revokeObjectURL(imageUrl);
        });

        Utils.initializeImageUploadEvents($(input));
      } else {
        Utils.showToast('danger', 'PNG 파일만 업로드할 수 있습니다.');
      }
    },

    //이메일 예약 전송 시 이전 시간 validation
    validateReserveTime() {
      reserveTimeValid = false;
      $('#reserveButton').on('click', function () {
        // Get current date and time
        let currentDate = new Date();

        // Get the selected date, time, hour, and minute
        let date = $('#reservePickReservePage').val(); // e.g., "2024.09.08"
        let time = $('#reserveMailTimeRangeReservePage').val(); // "morning" or "afternoon"
        let hour = parseInt($('#reserveMailHourReservePage').val(), 10); // e.g., "1" to "12"
        let minute = parseInt($('#reserveMailMinuteReservePage').val(), 10); // e.g., "00", "10", ..., "50"

        // Parse the date string into a Date object
        let [year, month, day] = date.split('-').map(Number);
        let selectedDate = new Date(year, month - 1, day, (hour % 12) + (time === 'afternoon' ? 12 : 0), minute);

        if (selectedDate < currentDate) {
          // Show the alert and return false
          $('.deny-reserve').addClass('active');
          reserveTimeValid = false;
        } else {
          // Proceed with the form submission or other actions
          $('.deny-reserve').removeClass('active');

          reserveTimeValid = true;
        }
      });
    },
    //(공통) 주소록, 이메일 테이블에서 데이터가 없을 시 표시
    checkTableData(tableId, noDataMessage) {
      function insertNoDataMessage() {
        const $table = $(tableId);
        const $noDataDiv = $('<div class="no-data-table">').text(noDataMessage);

        // 삽입할 위치가 있으면 그 뒤에 추가, 없으면 테이블 뒤에 추가
        $table.after($noDataDiv);
      }

      // 테이블이 비어있으면 메시지를 추가하는 코드
      const $tbody = $(tableId).find('tbody');
      if ($tbody.find('tr').length === 0) {
        insertNoDataMessage();
      }
    },

    initializeImageUploadEvents($element) {
      // Attach drag-and-drop event listeners to the passed element
      $element.on('dragover', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).css('border', '2px dashed #000'); // Optional: visual feedback
      });

      $element.on('dragleave', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).css('border', 'none'); // Remove visual feedback
      });

      $element.on('drop', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).css('border', 'none'); // Remove visual feedback

        const files = e.originalEvent.dataTransfer.files;
        if (files.length > 0 && files[0].type === 'image/png') {
          const $input = $(this).find('input[type="file"]');
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(files[0]);
          $input[0].files = dataTransfer.files;

          // Trigger the handleImageUpload function manually
          console.log(`이미지 드롭함`);
          Utils.handleImageUpload($input[0]);
        } else {
          Utils.showToast('danger', 'PNG 파일만 업로드할 수 있습니다.');
        }
      });

      // Listen for file input changes and handle uploads
      $element.find('input[type="file"]').on('change', function () {
        Utils.handleImageUpload(this);
      });
    },

    //이메일 콘텐츠 select2에 이미지 적용
    setImageContentInDropdown(selector, imageFiles) {
      const imagePath = '/static/images/icon/';

      $(selector).select2({
        templateResult: formatState,
        templateSelection: formatState,
        escapeMarkup: function (markup) {
          return markup;
        }, // HTML 이스케이프 방지
      });

      function formatState(state) {
        if (!state.id) {
          return state.text;
        }
        const imageFile = imageFiles[state.id];
        const text = $(state.element).text(); // 옵션의 텍스트를 가져옵니다.

        if (imageFile) {
          const imgTag = `<img src="${imagePath}${imageFile}" alt="${text}" style="width: 24px; height: 38px; vertical-align: middle; object-fit: contain; margin-right: 5px;" />`;

          return `<span style="display: flex; align-items: center;">${imgTag}${text}</span>`;
        }
        return text; // 이미지가 없는 경우 텍스트만 표시
      }
    },

    setMatericalContentInDropdown(selector, imageFiles) {
      $(selector).select2({
        templateResult: iconForamtState,
        templateSelection: iconForamtState,
        escapeMarkup: function (markup) {
          return markup;
        }, // Prevent HTML escaping
      });

      function iconForamtState(state) {
        if (!state.id) {
          return state.text; // Default to showing the text if no ID is present
        }

        const icon = imageFiles[state.id]; // Lookup the icon based on the option value (state.id)

        const imgTag = `<i class="material-icons" style="width: 24px; height: 24px; vertical-align: middle; object-fit: contain; margin-right: 5px;">${icon}</i>`;
        return `<span style="display: flex; align-items: center;">${imgTag} ${state.text}</span>`;
      }
    },

    // 초기화 시 모든 스타일을 적용하는 함수
    templateInitJsonStructure(data) {
      Utils.templateChangeJsonStructure(data, 'templateBackground', data['templateBackground']);
      Utils.templateChangeJsonStructure(data, 'bodyBackground', data['bodyBackground']);
      Utils.templateChangeJsonStructure(data, 'bodyBorder', data['bodyBorder']);
      Utils.templateChangeJsonStructure(data, 'borderSize', data['borderSize']);

      Utils.templateChangeJsonStructure(data, 'innerTopPadding', data['innerTopPadding']);
      Utils.templateChangeJsonStructure(data, 'innerBottomPadding', data['innerBottomPadding']);
      Utils.templateChangeJsonStructure(data, 'horizontalPadding', data['horizontalPadding']);
      Utils.templateChangeJsonStructure(data, 'fontFamily', data['fontFamily']);
      Utils.templateChangeJsonStructure(data, 'fontSize', data['fontSize']);
      Utils.templateChangeJsonStructure(data, 'textAlign', data['textAlign']);
      Utils.templateChangeJsonStructure(data, 'textColor', data['textColor']);
      Utils.templateChangeJsonStructure(data, 'linkTextColor', data['linkTextColor']);
      Utils.templateChangeJsonStructure(data, 'linkTextFontWeight', data['linkTextFontWeight']);
      Utils.templateChangeJsonStructure(data, 'linkTextTextDecoration', data['linkTextTextDecoration']);
      Utils.templateChangeJsonStructure(data, 'basicButtonBorderSize', data['basicButtonBorderSize']);
      Utils.templateChangeJsonStructure(data, 'basicButtonBorderColor', data['basicButtonBorderColor']);
      Utils.templateChangeJsonStructure(data, 'basicButtonBackgroundColor', data['basicButtonBackgroundColor']);
      Utils.templateChangeJsonStructure(data, 'basicButtonSize', data['basicButtonSize']);
      Utils.templateChangeJsonStructure(data, 'basicButtonAlignment', data['basicButtonAlignment']);

      Utils.templateChangeJsonStructure(data, 'basicButtonShape', data['basicButtonShape']);
      Utils.templateChangeJsonStructure(data, 'buttonDividerSize', data['buttonDividerSize']);

      return data;
    },

    // 특정 키와 값에 따라 스타일을 변경하는 함수
    templateChangeJsonStructure(data, key = null, value = null) {
      if (key && value !== null) {
        data[key] = value;
        switch (key) {
          case 'templateBackground':
            $('.content-body').css('background-color', rgbToHex(value, true)); // CSS use
            $('#templateBackground').css('background-color', rgbToHex(value, true)).val(rgbToHex(value, false)); // Value storing
            Utils.applyColorStyle($('#templateBackground'), rgbToHex(value, true));
            break;

          case 'bodyBackground':
            $('.component .box').css('background-color', rgbToHex(value, true));
            $('#bodyBackground').css('background-color', rgbToHex(value, true)).val(rgbToHex(value, false));
            Utils.applyColorStyle($('#bodyBackground'), rgbToHex(value, true));
            break;

          case 'borderSize':
            $('.component .box').css('border-width', value).css('border-style', 'solid');
            break;

          case 'bodyBorder':
            $('.component .box').css('border-color', rgbToHex(value, true));
            $('#bodyBorder').css('background-color', rgbToHex(value, true)).val(rgbToHex(value, false));
            Utils.applyColorStyle($('#bodyBorder'), rgbToHex(value, true));
            break;

          case 'innerTopPadding':
            $('.component .box').css('padding-top', value);
            break;

          case 'innerBottomPadding':
            $('.component .box').css('padding-bottom', value);
            break;

          case 'horizontalPadding':
            $('.component .box').css('padding-left', value).css('padding-right', value);
            break;

          case 'fontFamily':
            $('.component .box .text .default-style').css('font-family', value);
            $('#basicTextSettingHead').css('font-family', value);
            break;

          case 'fontSize':
            $('.component .box .text .default-style').css('font-size', value);
            $('#basicTextSettingHead').css('font-size', value);
            break;

          case 'textAlign':
            $('.component .box .text .default-style').css('text-align', value);
            $('#basicTextSettingHead').css('text-align', value);
            break;

          case 'textColor':
            $('.component .box .text .default-style').css('color', rgbToHex(value, true));
            $('#basicTextSettingHead').css('color', rgbToHex(value, true));
            $('#textColor').css('background-color', rgbToHex(value, true)).val(rgbToHex(value, false));
            Utils.applyColorStyle($('#textColor'), rgbToHex(value, true));
            break;

          case 'linkTextColor':
            $('#linkTextSettingHead').css('color', rgbToHex(value, true));
            $('#linkTextColor').css('background-color', rgbToHex(value, true)).val(rgbToHex(value, false));
            Utils.applyColorStyle($('#linkTextColor'), rgbToHex(value, true));
            createLinkStyle();
            break;

          case 'linkTextFontWeight':
            $('#linkTextSettingHead').css('font-weight', value);
            createLinkStyle();
            break;

          case 'linkTextTextDecoration':
            $('#linkTextSettingHead').css('text-decoration', value);
            createLinkStyle();
            break;

          case 'basicButtonBackgroundColor':
            $('.component .box .box-row.button .default-style .editor-a-button').css('background-color', rgbToHex(value, true));
            $('.component .box .box-row.layout-one .default-style .editor-a-button').css('background-color', rgbToHex(value, true));
            $('#buttonSettingHead').find('.custom-button').css('background-color', rgbToHex(value, true));
            $('#basicButtonBackgroundColor').val(rgbToHex(value, true));
            Utils.applyColorStyle($('#basicButtonBackgroundColor'), rgbToHex(value, true));
            break;

          case 'basicButtonBorderSize':
            $('.component .box .box-row.button .default-style .editor-a-button').css('border-width', value).css('border-style', 'solid');
            $('.component .box .box-row.layout-one .default-style .editor-a-button').css('border-width', value);
            break;

          case 'basicButtonBorderColor':
            $('.component .box .box-row.button .default-style .editor-a-button').css('border-color', rgbToHex(value, true)).css('border-style', 'solid');
            $('.component .box .box-row.layout-one .default-style .editor-a-button').css('border-color', rgbToHex(value, true));
            $('#buttonSettingHead').css('border-color', rgbToHex(value, true));
            $('#basicButtonBorderColor').val(rgbToHex(value, true));
            Utils.applyColorStyle($('#basicButtonBorderColor'), rgbToHex(value, true));
            break;

          case 'basicButtonSize':
            $('#buttonSettingHead').find('.custom-button').css('width', value);
            $('.component .box .box-row.button .default-style .editor-a-button').css('width', value);
            $('.component .box .box-row.layout-one .default-style .editor-a-button').css('width', value);
            break;

          case 'basicButtonAlignment':
            $('.component .box .box-row.button .default-style .editor-a-button').css('margin', value);
            $('.component .box .box-row.layout-one .default-style .editor-a-button').css('margin', value);
            break;

          case 'basicButtonShape':
            $('.component .box .box-row.button .default-style .editor-a-button').css('border-radius', value);
            $('.component .box .box-row.layout-one .default-style .editor-a-button').css('border-radius', value);
            $('.custom-button').css('border-radius', value);
            break;

          case 'buttonDividerSize':
          case 'divderColor':
          case 'buttonDividerShape':
            $('.component .box .editor-divider')
              .css('border-top-width', data['buttonDividerSize'])
              .css('border-top-style', data['buttonDividerShape'])
              .css('border-top-color', rgbToHex(data['divderColor'], true));
            Utils.applyColorStyle($('#divderColor'), rgbToHex(data['divderColor'], true));
            break;

          default:
            console.warn(`Unknown key: ${key}`);
        }
      }
    },
    //구독 취소 후 UI 변경 함수

    reSubscribeChangeUI() {
      $('#registerAgain').on('click', function () {
        $('.before-deny').css('display', 'flex');
        $('.after-deny').css('display', 'none');
      });
    },
    changeUnsubscribeChangeUI() {
      $('#denySubscribeYes').on('click', function () {
        // 현재 날짜와 시간을 가져옴
        var now = new Date();

        // 날짜와 시간을 '2024. 9. 25. 9:11 AM' 형식으로 포맷
        var year = now.getFullYear();
        var month = now.getMonth() + 1; // getMonth()는 0부터 시작하므로 +1 필요
        var day = now.getDate();
        var hours = now.getHours();
        var minutes = now.getMinutes().toString().padStart(2, '0'); // 2자리 맞춤
        var ampm = hours >= 12 ? 'PM' : 'AM';

        // 12시간제로 변경
        hours = hours % 12;
        hours = hours ? hours : 12; // 0시를 12시로 설정

        // 최종 포맷된 시간
        var currentTime = `${year}. ${month}. ${day}. ${hours}:${minutes} ${ampm}`;

        // UI 업데이트
        $('.before-deny').css('display', 'none');
        $('.after-deny').css('display', 'flex');

        // deny-call-time에 현재 시간을 업데이트
        $('.deny-call-time').text(currentTime);
      });
    },

    //테스트 발송 이메일 validation
    testEmailInputValidation() {
      // 이메일 입력 필드에 키 입력 시마다 유효성 검사 수행
      $('.email-input').on('input', function () {
        validateEmails();
      });
      // 발송 버튼 클릭 이벤트
      $('#textSendButton').on('click', function (event) {
        var validation = validateEmails();
        console.log(`validation check: ${validation.isValid} all email valid: ${validation.allEmailsValid}`);
        if (!validation.isValid) {
          alert('이메일을 최소 1개 이상 입력해야합니다.');
          event.preventDefault(); // 모달 닫히지 않음
          return false;
        }

        if (!validation.allEmailsValid) {
          alert('이메일 양식이 맞지 않습니다.');
          event.preventDefault(); // 모달 닫히지 않음
          return false;
        }

        if (validation.isValid && validateEmail.allEmailsValid) {
          return true;
        }
      });
      function validateEmail(email) {
        var emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return emailPattern.test(email);
      }

      // 이메일 입력 유효성 검사
      function validateEmails() {
        var isValid = false;
        var allEmailsValid = true;
        var emailInputs = $('.email-input');

        // 최소 한 개의 이메일 입력이 있는지 확인
        emailInputs.each(function () {
          var email = $(this).val().trim();
          if (email !== '') {
            isValid = true; // 최소 1개의 이메일이 입력됨
            if (!validateEmail(email)) {
              allEmailsValid = false; // 이메일 양식이 올바르지 않음
            }
          }
        });

        // 이메일 형식 및 최소 입력 여부에 따라 배경색 변경
        if (isValid && allEmailsValid) {
          $('#textSendButton').addClass('valid'); // 버튼 색상 변경
        } else {
          $('#textSendButton').removeClass('valid'); // 기본 색상으로 변경
        }

        return { isValid: isValid, allEmailsValid: allEmailsValid };
      }

      $('#testMailSend').on('hidden.bs.modal', function () {
        $('.email-input').val(''); // 모든 이메일 입력 필드를 초기화
        $('#textSendButton').removeClass('valid'); // 버튼 색상도 초기화
      });
    },

    applyColorStyle(element, color) {
      // element.css('background-color', color); // 버튼 배경색 변경

      if (color.toLowerCase() !== '#ffffff') {
        element.addClass('non-white'); // 흰색이 아닌 경우

        element.css('border', 'none'); // 흰색이 아닐 때 테두리 제거
      } else {
        element.removeClass('non-white'); // 흰색일 경우 클래스 제거

        element.css('border', '1px solid #dfe0e4'); // 흰색일 때 테두리 추가
      }
    },
    // 이메일, 비밀번호 유효성 검사 함수
    validIDAndPassword() {
      var loginID = $('#loginID');
      var loginPassword = $('#loginPassword');
      var isValid = true;

      // 이메일 필드 유효성 검사
      if (loginID.val() === '') {
        loginID.addClass('is-invalid');
        loginID.siblings('.invalid-feedback').show(); // invalid-feedback 표시
        isValid = false;
      } else {
        loginID.removeClass('is-invalid');
        loginID.siblings('.invalid-feedback').hide(); // invalid-feedback 숨김
      }

      // 비밀번호 필드 유효성 검사
      if (loginPassword.val() === '') {
        loginPassword.addClass('is-invalid');
        loginPassword.siblings('.invalid-feedback').show(); // invalid-feedback 표시
        isValid = false;
      } else {
        loginPassword.removeClass('is-invalid');
        loginPassword.siblings('.invalid-feedback').hide(); // invalid-feedback 숨김
      }

      // 로그인 시도
      return isValid;
    },
    //구독하기 이메일 valid 체크
    validateEmailInput() {
      var email = $('#emailSubscribe').val().trim(); // 이메일 입력 값
      var isAgreed = $('#infoGetAgree').is(':checked'); // 체크박스 상태

      // 이메일 입력 여부와 체크박스 선택 여부를 검사
      if (email === '') {
        $('.invalid-feedback').show(); // 이메일 오류 메시지 표시
        return false;
      } else {
        $('.invalid-feedback').hide(); // 이메일 오류 메시지 숨김
      }

      if (!isAgreed) {
        Utils.showToast('danger','개인정보 수집 및 이용에 동의해야 합니다.');

        return false;
      }

      return true; // 조건을 만족하면 true 반환
    }
  };
})();
